/*/////////////////////////////////////////////////////////////////////////////////////////// INFORMATION */
.products{
    .scrollposition{position:absolute; margin-top:-164px;}

    .filter{
        .area{width:100%; padding:30px 0px 10px; background-color:$corDefault;
            .btn-filter{display:table; margin:auto; padding:12px 0px;
                .toggle-btn{position:relative; display:inline-block; width:54px; height:28px; background-color:#e4e4e4; border-radius:28px; border:1px solid #fff; overflow:hidden; vertical-align:middle; box-shadow:inset -2px 2px 15px -5px #000, -5px 0 10px -5px #000; cursor:pointer; @include transition($animatDefault);
                    &:before{position:absolute; content:''; left:0; top:0; width:26px; height:26px; border-radius:100%; background-color:#fff; @include transition($animatDefault);}
                    &.active{background-color:darken($corDefault, 10%); box-shadow:inset -2px 2px 15px -5px #000, 5px 0 10px -5px #000; cursor:pointer;
                        &:before{left:26px;}
                    }
                }
                .clean-btn{padding:4px 0px; cursor:pointer;
                    icon{display:inline-block; width:19px; height:21px; text-align:center; color:#fff; font-size:20px; vertical-align:middle; transform-origin:50% 50%;
                        &:before{display:table-cell;}
                    }
                    &:active{ icon{ transform: rotate(-160deg); @include transition( 200ms ease-in-out ); } }
                    &:not(:active){ icon{ transform: rotate(0deg); @include transition( 100ms ease-in-out ); } }
                }
                span{display:inline-block; padding:0px 10px; color:#fff; font-size:13px; font-family:'Bold'; text-transform:uppercase; vertical-align:middle;}
            }
        }
    }

    .itens{position:relative; padding-bottom:40px; min-height:320px; @include transition($animatDefault);
        .loader{position:relative; left:0; right:0; top:60px; margin:auto; z-index:0;}
        .noresults{position:relative; top:60px; color:$corDefault; font-size:18px; text-align:center; text-transform:uppercase;}
        .billhead{width:100%; padding:30px 0px; text-align:center; 
            .btn-link, .btn-file, .btn-favorites{display:inline-block; margin:0px 10px; cursor:pointer;
                icon{display:inline-block; width:42px; height:42px; border:2px solid $corDefault; border-radius:100%; color:$corDefault; font-size:20px; text-align:center; line-height:36px; vertical-align:middle; @include transition($animatDefault);}
                span{display:inline-block; padding:0px 10px; color:$corLightG; font-family:'Bold'; font-size:12px; text-transform:uppercase; vertical-align:middle;}
                &.active{
                    icon{ background-color:$corDefault; color:#fff;}
                }
            }
        }
        .box-item{position:relative; height:420px; background-color:#fff; margin-bottom:30px;
            icon{position:absolute; left:10px; top:10px; color:$corLightG; font-size:28px; z-index:1; cursor:pointer;
                &.fav_cheio{color:$corDefault;}
            }
            .item{position:absolute; left:0px; top:0px; width:100%; height:100%; padding:30px 10px 10px; cursor:pointer;
                .imag{width:100%; height:75%; @include backgrd(''); background-size:contain;} 
                .text{display:table; width:100%; height:25%; text-align:center;
                    .marca{display:block; padding-bottom:2px; color:$corLightG; font-size:14px; text-transform:uppercase; line-height:125%;}
                    .nome{display:block; color:$corDefault; font-family:'Bold'; font-size:14px; text-transform:uppercase; line-height:125%;}
                }
            }
        }
        .btn-more{display:table; position:relative; width:180px; height:46px; margin:10px auto; background-color:$corDefault; color:#fff; font-family:'SemiBold'; font-size:13px; text-align:center; text-transform:uppercase; line-height:46px; cursor:pointer; z-index:0;}
        &.v-enter, &.v-leave{ opacity:0; }
        &.v-enter-to{ opacity:1; @include transition($animatDefault 250ms); }
        &.v-leave-to{ opacity:0; @include transition((all $animatDefault 250ms, opacity $animatDefault 0ms)); }

        .col-item{@include transition(800ms); overflow:hidden; 
            &.v-enter, &.v-leave{ opacity:0; }
            &.v-enter-to{ opacity:1; @include transition(500ms ease-in-out 250ms); }
            &.v-leave-to{ opacity:0; @include transition(0ms 0ms); }
        }  

    }
    
    .detail{@include transition($animatDefault);
        .communi{float:left; position:relative; width:100%; padding:60px 0px;
            .btn-close{position:absolute; right:10px; top:30px; color:$corDefault; font-size:20px; cursor:pointer;}
            .item{position:relative; max-width:420px; height:420px; margin: 0px auto 20px; background-color:#fff; padding:30px 10px 10px;
                icon{position:absolute; left:10px; top:10px; color:$corLightG; font-size:28px; z-index:1; cursor:pointer;
                    &.fav_cheio{color:$corDefault;}
                }
                .imag{width:100%; height:100%; @include backgrd(''); background-size:contain;} 
            }
            .links{text-align:center; margin-bottom:40px;
                .btn-info, .btn-file{display:block; margin:10px 0px; cursor:pointer;
                    icon{display:inline-block; width:42px; height:42px; border:2px solid $corDefault; border-radius:100%; color:$corDefault; font-size:20px; text-align:center; line-height:36px; vertical-align:middle;}
                    span{display:inline-block; padding:0px 10px; color:$corLightG; font-family:'Bold'; font-size:13px; text-transform:uppercase; vertical-align:middle;}
                }
            }
            .descri{padding:0px 40px;
                h3{display:block; color:$corLightG; font-size:22px; text-transform:uppercase;}
                h4{display:block; margin-bottom:40px; color:$corDefault; font-family:'Bold'; font-size:22px; text-transform:uppercase; width:75%;}
                h6{display:block; color:$corDefault; font-family:'Bold'; font-size:16px;}
                p{display:block; margin-bottom:40px; font-size:16px;}
                h5{display:block; margin-bottom:20px; color:$corDefault; font-family:'Bold'; font-size:18px; text-transform:uppercase;
                    a{color:$corDefault;}
                }
                .nutri{
                    li{float:left; width:100%;
                        h6{margin:5px 0px -5px 5px;}
                        .col{float:left; width:50%; padding:15px 0px;}
                        icon{display:inline-block; width:60px; height:60px; border-radius:100%; background-color:$corDefault; color:#fff; font-size:34px; text-align:center; line-height:60px; vertical-align:middle;}
                        .box{display:inline-block; padding:0px 10px; vertical-align:middle;}
                        .type{display:block; font-size:16px; text-transform:uppercase;}
                        .value{display:block; font-family:'Bold'; font-size:18px;}
                        &.half{width:50%;
                            .col{width:100%;}
                        }
                    }
                }
            }
        }

        &.v-enter-to{opacity:1; @include transition(all $animatDefault 500ms); }
        &.v-enter, &.v-leave{ opacity:0;}
    }
}

.information{
    .boxes{
        .btn-back{display:none;}
    }
}

@media (max-width:$screen-md-max) {
    .products{
        .itens{
            .box-item{height:380px;
                .item{
                    .imag{height:75%;} 
                    .text{height:25%;}
                }
            }
        }
    }
}

@media (max-width:$screen-sm-max) {
    .products{
        .scrollposition{margin-top:-236px;}
        .filter .area{
            .btn-filter{display:block;}
            .afbt{text-align:center;
                .col-md-12{display:inline-block;}
            }
        }
        .itens{
            .billhead{
                .btn-link, .btn-file, .btn-favorites{margin:0px 5px;}
            }
        }
        .detail{
            .communi{
                .descri{padding:0px 0px;
                    h4{width:100%;}
                    .nutri{
                        li{
                            .col{width:100%}
                            &.half{width:100%;}
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:$screen-xs-max) {
    .products{
        .scrollposition{margin-top:-60px;}
        .filter{
            .area{
                .btn-filter{padding:12px 0px;
                    .toggle-btn{width:42px; height:22px; border-radius:22px;
                        &:before{width:20px; height:20px;}
                        &.active{
                            &:before{left:20px;}
                        }
                    }
                    .clean-btn{
                        icon{font-size:16px;}
                    }
                    span{padding:0px 8px; font-size:12px;}
                }
            }
        }
        .itens{
            .billhead{
                .btn-link, .btn-file, .btn-favorites{margin:4px;
                    icon{width:28px; height:28px; font-size:14px; line-height:24px;}
                    span{padding:0px 5px; font-size:11px;}
                }
            }
        }
        .detail{
            .communi{
                .links{
                    .btn-info, .btn-file{
                        icon{width:28px; height:28px; font-size:14px; line-height:24px;}
                        span{padding:0px 5px; font-size:11px;}
                    }
                }
            }
        }
    }
}

@media (max-width:$screen-xs-min - 1) {
    .products{
        .itens{
            .col-item{padding-left:5px; padding-right:5px;}
            .box-item{height:240px; margin-bottom:10px;
                .item{
                    .imag{height:70%;} 
                    .text{height:30%;
                        .marca{font-size:10px;}
                        .nome{font-size:10px;}
                    }
                }
            }
        }
    }
}