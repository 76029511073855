
@mixin animation($animation) { 
    -moz-animation: $animation;
    -o-animation: $animation;
    -webkit-animation: $animation;
    animation: $animation;
}

@mixin transition($transition) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});  
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin backgrd($img) {
    @if ($img != '') {
        background-image:url($img);
    }
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
}

$animatDefault: 250ms ease-in-out;


$corDefault: rgb(177,85,51);
$corDarkG: #414141;
$corLightG: #656565;



$burg_timer: 250ms forwards ease-in-out;

@include keyframes(open_burg_span) {
    0% {height:4px; margin:4px 0px 0px;}
    50% {height:0px; margin:0px 0px 0px;}
    100% {height:0px; margin:0px 0px 0px;} 
}
$open-burg_span: open_burg_span $burg_timer;

@include keyframes(close_burg_span) {
    0% {height:0px; margin:0px 0px;}
    50% {height:0px; margin:0px 0px;}
    100% {height:4px; margin:4px 0px;} 
}
$close-burg_span: close_burg_span $burg_timer;

@include keyframes(open_burg_before) {
    0% {transform: rotate(0deg); margin:0px auto 0px;}
    50% {transform: rotate(0deg); margin:9px auto 0px; width:32px;}
    100% {transform: rotate(45deg); margin:9px auto 0px; width:32px;}
}
$open-burg_before: open_burg_before $burg_timer;

@include keyframes(close_burg_before) {
    0% {transform: rotate(45deg); margin:9px auto 0px; width:32px;}
    50% {transform: rotate(0deg); margin:9px auto 0px; width:32px;}
    100% {ransform: rotate(0deg); margin:0px auto 0px;}
}
$close-burg_before: close_burg_before $burg_timer;

@include keyframes(open_burg_after) {
    0% {transform: rotate(0deg); margin:0px auto 0px;}
    50% {transform: rotate(0deg); margin:-4px auto 0px; width:32px;}
    100% {transform: rotate(-45deg); margin:-4px auto 0px; width:32px;}
}
$open-burg_after: open_burg_after $burg_timer;

@include keyframes(close_burg_after) {
    0% {transform: rotate(-45deg); margin:-4px auto 0px; width:32px;}
    50% {transform: rotate(0deg); margin:-4px auto 0px; width:32px;}
    100% {transform: rotate(0deg); margin:0px auto 0px;}
}
$close-burg_after: close_burg_after $burg_timer;
