/*/////////////////////////////////////////////////////////////////////////////////////////// HOME */
.home-top{width:100%; height:auto; background-color:$corDefault; padding-bottom:30px; box-sizing:content-box;
    &:before{content:''; position:absolute; width:100%; height:30px; background-color:#fff; z-index:0;}
    .box-slider{position:relative; height:500px;  background-color:#fff; z-index:1;
        .slider{position: relative; display:block; width:100%; height:100%; 
            .item{position:relative; height:500px;  border:none;
                .imag{position:absolute; width:100%; height:100%; @include backgrd(''); z-index:1;}
                .box{position:absolute; bottom:10px; left:40px; max-width:75%; @include transition($animatDefault 250ms); z-index:3;
                    .title{display:block; color:#fff; font-family:'Bold'; font-size:34px; text-transform:uppercase; line-height:100%;}
                    .subtitle{display:block; margin:0px 0px 10px; color:#fff; font-family:'SemiBold'; font-size:20px; color:#fff; line-height:110%;}
                    .link{display:table; color:$corDefault; font-family:'Bold'; font-size:15px; text-transform:uppercase;
                        icon{display:inline-block; width:auto; max-width:0px; vertical-align:bottom; overflow:hidden; @include transition($animatDefault);}
                        &:hover{
                            icon{max-width:50px;}
                        }
                    }
                }
                &:after{position:absolute; content:''; width:100%; height:100%; z-index:2;
                    background: rgba(0,0,0,0);
                    background: -moz-linear-gradient(top, rgba(0,0,0,0) 50%, rgba(0,0,0,0.25) 75%, rgba(1,12,18,0.5) 100%);
                    background: -webkit-gradient(left top, left bottom, color-stop(50%, rgba(0,0,0,0)), color-stop(75%, rgba(0,0,0,0.25)), color-stop(100%, rgba(1,12,18,0.5)));
                    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 50%, rgba(0,0,0,0.25) 75%, rgba(1,12,18,0.5) 100%);
                    background: -o-linear-gradient(top, rgba(0,0,0,0) 50%, rgba(0,0,0,0.25) 75%, rgba(1,12,18,0.5) 100%);
                    background: -ms-linear-gradient(top, rgba(0,0,0,0) 50%, rgba(0,0,0,0.25) 75%, rgba(1,12,18,0.5) 100%);
                    background: linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,0.25) 75%, rgba(1,12,18,0.5) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#010c12', GradientType=0 );
                }
                &.slick-current{
                    .box{bottom:50px;}
                }
            }
        }
        .slider-arrow{position:absolute; top:0; bottom:0; left:50px; width:50px; height:114px; margin:auto; z-index:1;
            .slick-arrow{position:absolute; display:block; width:50px; height:50px; padding:0; border:1px solid #fff; border-radius:100%; background:transparent; outline:none; color:transparent; font-size:0; line-height:0; cursor:pointer;}
            .slick-prev{top:0;
                &:before{content:'\e80c'; color:#fff; font-family:'icons'; font-size:18px; line-height:50px;}
            }
            .slick-next{bottom:0;
                &:before{content:'\e80b'; color:#fff; font-family:'icons'; font-size:18px; line-height:50px;}
            }
            #slick-conter{position:absolute; bottom:-2.5px; left:-2.5px; z-index:1; cursor:pointer; display:none;}
        }
    }
    .box-finder{position:relative; height:500px; background-color:#fff; z-index:1;
        .imag{width:100%; height:270px; @include backgrd('../../img/finder.jpg');}
        .info{position:relative; display:table; width:100%; height:230px; padding:60px 20px 20px; background-color:#fff; text-align:center;
            .icon{position:absolute; left:0; right:0; top:-40px; width:80px; height:80px; margin:auto; border-radius:100%; background-color:$corDefault; @include backgrd('../../img/tabela.svg'); background-size:80%;}
            .more{display:block; color:$corDefault; font-family:'Bold'; font-size:15px; text-transform:uppercase;}
            .title{display:block; margin-bottom:10px; color:$corDarkG; font-family:'Bold'; font-size:16px; text-transform:uppercase;}
            .form-group{margin-bottom:0px;
                select{border:1px solid #bcbcbc;}
            }
        }
    }
}

.home{
    .boxes{
        .btn-back{display:none;}
    }
}

@media (max-width:$screen-sm-max) {
    .home-top{
        .box-slider .slider .item .box .title{font-size:28px;}
    }
}

@media (max-width:$screen-xs-max) {
    .home-top{
        .col-slider{padding:0;}
        .col-finder{margin:auto; max-width:460px;}
        .box-slider{height:420px;
            .slider{
                .item{height:420px;
                    .box{left:20px;}
                }
            }
            .slider-arrow{left:20px; display:none;
                .slick-prev{
                    &:before{display:none;}
                }
                .slick-next{display:none !important;}
                #slick-conter{top:-2px; bottom:inherit;}
            }
        }
        .box-finder{height:auto; margin:20px 0px 0px;
            .imag{height:180px;}
            .info{height:auto;}
        }
    }
}

@media (max-width:$screen-xs-min - 1) {

}