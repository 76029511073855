/*/////////////////////////////////////////////////////////////////////////////////////////// BODY */
html,body,div,pre,form,fieldset,input,h1,h2,h3,h4,h5,h6,p,textarea,ul,ol,li,dl,dt,dd,blockquote,th,td,
/* HTML 5 elements */ abbr,article,aside,audio,canvas,details,figcaption,figure,footer,header,hgroup,
mark,menu,meter,nav,output,progress,section,summary,time,video { margin: 0; padding: 0; }
table { border-collapse: collapse; border-spacing: 0; }
fieldset,img { border: 0; }
address,caption,cite,code { font-style: normal; font-weight: normal; }
ul { list-style: none; }
caption,th { text-align: left; }
h1,h2,h3,h4,h5,h6 { font-size: 100%; font-weight: normal; }
q:before,q:after { content: ''; }
abbr,acronym { border: 0; }
a { outline:none; text-decoration:none; color:#000; cursor:pointer; } a:hover { color:#000 } a img { border:none; }
:focus { outline:none; }/* For Chrome */
*, *:before, *:after { -webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box; }
body,html{ width:100%; height:100%; font-family:'Regular', sans-serif; font-size:100%; color:$corDarkG; background:#f6f6ee; }

main{position:relative; display:block; width:100%; padding-top:184px; @include calc(min-height, '100% - 160px');}
[v-cloak] {display: none;}
body.no-scroll {overflow: hidden;}
section{position:relative; width:100%;}
.middle{display:table-cell; height:100%; vertical-align:middle;}
.row{font-size:0;}

@media (max-width:$screen-xs-max) {
    main{padding-top:60px;}
}

/*/////////////////////////////////////////////////////////////////////////////////////////// HEADER */
header{position:absolute; background-color:#fff; width:100%; z-index:15;
    .billhead{position:relative; width:100%; height:114px; border-bottom:1px solid #e0dddc; z-index:4;
        .hamburger{float:left; position:relative; margin:20px 0px 0px 20px; cursor:pointer; display:none; z-index:1;
            span{display:block; width:32px; height:4px; margin:4px 0px; background-color:$corDefault; @include animation($close-burg_span); border-radius:5px;}
            &:before{content:''; display:block; width:32px; height:4px; background-color:$corDefault; @include animation($close-burg_before); border-radius:5px;}
            &:after{content:''; display:block; width:32px; height:4px; background-color:$corDefault; @include animation($close-burg_after); border-radius:5px;}
            &.open{
                span{@include animation($open-burg_span); height:0px; margin:0px 0px;}
                &.show{ @include opacity(1); @include transition($animatDefault);}
                &:before{ @include animation($open-burg_before); transform: rotate(45deg); margin:9px auto 0px; width:32px;}
                &:after{ @include animation($open-burg_after); transform: rotate(-45deg); margin:-4px auto 0px; width:32px;}
            }
        }
        .logo{position:absolute; top:0; bottom:0; left:0; right:0; width:139px; height:88px; margin:auto; @include backgrd('../../img/logo-sogrape.png');}
        .idiom{position:absolute; top:26px; right:20px;
            a{display:inline-block; width:32px; height:32px; margin-left:6px; border-radius:100%; border:1px solid $corDefault; color:$corDefault; font-family:'SemiBold'; font-size:10px; line-height:32px; text-align:center;
                &.active{background-color:$corDefault; color:#fff;}
            }
        }
    }
    nav{position:relative; width:100%; height:70px; z-index:2;
        .menu{display:flex; justify-content:space-between; align-items:center; text-align:center;
            .link{position:relative; display:table; min-height:34px; margin-top:16px;
                &:hover, &.active{
                    a{color:$corDefault;}
                    &:after{opacity:1;}
                }   
                &:after{content:''; position:absolute; bottom:-10px; left:0; right:0; width:8px; height:8px; margin:auto; border-radius:100%; background-color:$corDefault; opacity:0; @include transition($animatDefault);}
            }
            a{position:relative; display:table-cell; padding:0px 16px; font-family:'SemiBold'; font-size:12px; text-transform:uppercase; text-align:center; word-spacing:1px; vertical-align:middle; @include transition($animatDefault);
                br{display:block;}
            }
        }
    }
}


@media (max-width:$screen-sm-max) {
    header{
        nav{
            .menu{
                a{font-size:10px;}
            }
        }
    }
}

@media (max-width:$screen-xs-max) {
    header{position:fixed;
        .billhead{height:60px;
            .hamburger{display:block;}
            .logo{width:76px; height:48px;}
            .idiom{top:14px;}
        }
        nav{position:fixed; width:100%; height:100%; top:0; padding-top:60px;
            &.v-enter, &.v-leave-active{height:0%; @include transition(0ms);
                .menu{
                    .link{ height:0%; }
                }
            }
            &.v-leave-active{height:100%; @include transition($animatDefault);
                .menu{
                    .link{ height:0%; }
                }
            }
            .menu{height:100%; flex-wrap:wrap; padding:0; overflow:hidden; @include transition($animatDefault);
                .link{display:flex; width:100%; height:20%; margin-top:0; background-color:#fff; border-bottom:1px solid #e0dddc; overflow:hidden; align-items:center; @include transition($animatDefault);}
                a{width:100%; padding:0; font-size:18px;}
            }

        }
    }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// MAIN */
.banner{width:100%; height:460px; margin:auto; @include backgrd('');
    .info{position:absolute; bottom:0; width:46%; padding:0px 0px 40px 20px;
        .title{display:block; color:#fff; font-family:'Bold'; font-size:34px; text-transform:uppercase; line-height:125%;}
        .text{display:block; color:#fff; font-family:'SemiBold'; font-size:20px; line-height:125%;}
    }
}

@media (max-width:$screen-xs-max) {
    .banner{height:240px;
        .info{width:100%; padding:0px 0px 20px 20px;
            .title{font-size:28px;}
            .text{font-size:18px;}
        }
    }
}

.loading{display:-webkit-flex; display:flex; width:100%; height:100%; -webkit-box-align:center; align-items:center; -webkit-box-pack:center; justify-content:center;
    &:before{content:''; position:absolute; top:0; bottom:0; left:0; right:0; margin:auto; display:inline-flex; width:48px; height:48px; border:5px solid transparent; border-top:5px solid $corDefault; border-radius:50%; vertical-align:middle;}
    &:after{content:''; display:inline-flex; width:44px; height:44px; border:1px solid #eee; border-radius:50%; vertical-align:middle;}
}

/*/////////////////////////////////////////////////////////////////////////////////////////// FORMS */
.form-group{float:left; position:relative; width:100%; text-align:left; margin-bottom:20px;
    input, select{display:block; position:relative; width:100%; padding:10px; height:52px; border:0; background-color:#fff; color:$corDarkG; font-family:'Regular'; font-size:13px; box-shadow:none; outline:0;
        &:-webkit-autofill{-webkit-box-shadow: 0 0 0px 1000px #fff inset;}
        &::-webkit-input-placeholder {color:$corDarkG;}
        &::-moz-placeholder {color:$corDarkG;}
        &:-ms-input-placeholder {color:$corDarkG;}
    }
    input[disabled], select[disabled]{background-color:#efefef;}
    input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {-webkit-appearance:none; -moz-appearance:none; appearance:none; margin:0; }
    select{-webkit-appearance:none; -moz-appearance:none; appearance:none; text-indent: 0.01px; text-overflow:''; &::-ms-expand{display: none;}
        option{color:$corDarkG}
        &.empty{color:$corDarkG;}
    }
    &:before{position:absolute; top:20px; right:10px; color:$corDefault; font-family:'icons'; z-index:10;}
    &.select{
        select{padding:0px 30px 0px 20px;}
        &:before{content:'\e809'; font-size:12px;}
    }
    &.search{
        input{padding:0px 30px 0px 20px;}
        &:before{content:'\e857'; font-size:12px;}
    }
    .dropdown{display:block; position:relative; width:100%; height:52px; border:0; background-color:#fff; color:$corDarkG; font-family:'Regular'; font-size:13px; box-shadow:none; outline:0;
        .dropdown-toggle{position:relative; width:100%; height:100%; border:0; display:flex; align-items:center; justify-content:center;
            &:before{ position:absolute; content:'\e809'; top:20px; right:10px; color:$corDefault; font-size:12px; font-family:'icons'; z-index:10}
        }
        .selected-tag{width:100%; height:auto; margin:auto; padding:0px 40px 0px 20px; border:0; background:0; line-height:110%; align-self:left;}
        input[type=search], input[type=search]:focus{position:absolute; top:0; left:0; width:100% !important; height:100%; padding:0px 40px 0px 20px; color:$corDarkG; font-family:'Regular'; font-size:13px; z-index:1;}
        .open-indicator{display:none;}
        .spinner{top:12px; right:4px; width:24px; height:24px; border-top:3px solid hsla(0,0%,39%,.1); border-right:3px solid hsla(0,0%,39%,.1); border-bottom:3px solid hsla(0,0%,39%,.1); border-left:3px solid rgba(60,60,60,.45); opacity:1;}
        .dropdown-menu{ overflow-x:hidden; border:none;
            li{width:100%; margin:0; min-height:16px !important;
                a{width:100%; height:auto; padding:5px 20px; line-height:20px; color:$corDarkG !important; font-family:'Regular'; font-size:13px !important; white-space:inherit !important; text-decoration:none !important;}
            }
            .active a{ background-color:#f0f0f0; }
            .highlight a{ color:#fff !important; background-color:$corDefault;  }
        }
    }    

}

.box-finder{
    .form-group{
        .dropdown{font-family:'SemiBold'; border:1px solid rgba(60, 60, 60, 0.5);
            input[type=search]:focus{background-color:#fff;}
        }
    }
}

.bar-links, .pages{
    .form-group{
        .dropdown{
            .selected-tag{text-transform:uppercase;}
            a{text-transform:uppercase;}
        }
    }
}


/*/////////////////////////////////////////////////////////////////////////////////////////// FOOTER */
footer{
    .billhead{display:flex; flex-wrap:wrap; width:100%; height:90px; border-top:1px solid $corDefault; background-color:#fff; font-size:0; align-items:center; padding:5px 0px;
        .links{order:1; display:inline-block; width:80%; padding:0px 0px 8px 35px; text-align:left; align-self:flex-end; vertical-align:bottom;
            p{display:inline-block; color:$corLightG; font-family:'SemiBold'; font-size:11px; text-transform:uppercase;
                span{padding:0px 5px;
                    a{padding:0px 5px; color:$corLightG;}
                }
            }
        }
        .logo{order:2; display:inline-block; width:20%; padding:0px 40px 0px 0px; text-align:right;
            a{display:inline-block; width:84px; height:60px;  @include backgrd('../../img/logo-sogrape_negocio.png');}
        }
    }
    .baseboard{display:flex; flex-wrap:wrap; width:100%; height:70px; padding:20px 0px; background-color:#eeeeee; align-items:center; justify-content:center; text-align:center;
        span{order:1; display:inline-block; margin:0px 10px 0px; color:$corLightG; font-family:'Bold'; font-size:16px; text-transform:uppercase; vertical-align:super;}
        .logo-winmoderation{order:2; display:inline-block; width:131px; height:29px; margin:0px 10px; @include backgrd('../../img/logo-winmoderation.png');}
    }
}


@media (max-width:$screen-sm-max) {
    footer{
        .billhead{
            .links{}
            .logo{}
        }
    }
}

@media (max-width:$screen-xs-max) {
    footer{
        .billhead{align-content:center; padding:0px 0px;
            .links{width:70%; padding:15px 0px 0px 0px; text-align:center; align-self:auto; vertical-align:center;
                p{text-align:center; font-size:9px;
                    span{display:block;
                        a{display:inline-block;}
                    }
                }
            }
            .logo{width:30%; padding:0px 10px 0px 0px; text-align:center;
                a{width:78px; height:56px;}
            }
        }
        .baseboard{
            span{font-size:12px;}
        }
    }
}

@media (max-width:$screen-xs-min - 1) {
    footer{
        .baseboard{align-content:center; padding:0px 0px;
            span{order:3; width:100%; margin:5px 10px 0px;}
        }
    }
}


/*/////////////////////////////////////////////////////////////////////////////////////////// AGE-CHECK */
.age-check{
    .billhead .hamburger{display:none;}
    nav{display:none;}
    main{background-color:$corDefault}

    .area-age{display:table; width:720px; margin:0px auto 60px; color:#fff; text-align:center; 
        h2{display:block; font-family: 'Bold'; font-size:46px; text-transform:uppercase; margin-bottom:10px;}
        h3{display:block; font-family:'SemiBold'; font-size:20px; margin-bottom:20px; line-height:120%;}
        h4{display:block; font-family: 'Regular'; font-size:16px; margin-bottom:20px; line-height:120%;}
        h5{display:block; font-family: 'Regular'; font-size:11px; line-height:120%;}
        form{display:block;  margin-top:10px;
            .age, .contry{float:left; width:50%; padding:0px 10px;
                label{float:left; width:100%; color:#fff; font-family:'SemiBold'; font-size:20px; margin-bottom:10px;}
            }
            .form-group{
                &.day, &.month, &.year{float:left; width:33.33%; padding:0px 10px;}
                &.nation{float:left; width:100%; padding:0px 10px;}
                &:before{right:20px;}
                select option:first-child{display:none;}
            }
            .remeber{float:left; width:100%; padding:0px 10px;
                .form-group{text-align:center;}
                input{display:inline-block; width:16px; height:16px; margin-right:5px; vertical-align:middle; cursor:pointer;}
            }
            button{display:block; width:190px; height:42px; margin:0px auto 20px;  border:2px solid #fff; background-color:transparent; color:#fff; font-family:'SemiBold'; font-size:17px; text-transform:uppercase; cursor:pointer;}
        }
        a{color:#fff; text-decoration:underline;}
    }
}


@media (max-width:$screen-xs-max) {
    .age-check{
        .area-age{width:100%; max-width:480px; margin:10px auto 20px;
            h2{font-size:36px; margin-bottom:10px;}
            h3{font-size:16px; margin-bottom:20px;}
            h4{font-size:14px; margin-bottom:20px;}
            form{
                .age, .contry{width:100%; padding:0px;
                    label{font-size:18px;}
                }
                .form-group{
                    &.day, &.month, &.year{float:left; width:33.33%; padding:0px 10px;}
                    &.nation{float:left; width:100%; padding:0px 10px;}
                }
                .remeber{float:left; width:100%; padding:0px 10px;
                    .form-group{text-align:center;}
                    input{display:inline-block; width:16px; height:16px; margin-right:5px; vertical-align:middle; cursor:pointer;}
                }
            }
        }
    }
}