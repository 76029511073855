@charset "UTF-8";
.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

@-webkit-keyframes open_burg_span {
  0% {
    height: 4px;
    margin: 4px 0px 0px;
  }
  50% {
    height: 0px;
    margin: 0px 0px 0px;
  }
  100% {
    height: 0px;
    margin: 0px 0px 0px;
  }
}

@-moz-keyframes open_burg_span {
  0% {
    height: 4px;
    margin: 4px 0px 0px;
  }
  50% {
    height: 0px;
    margin: 0px 0px 0px;
  }
  100% {
    height: 0px;
    margin: 0px 0px 0px;
  }
}

@-o-keyframes open_burg_span {
  0% {
    height: 4px;
    margin: 4px 0px 0px;
  }
  50% {
    height: 0px;
    margin: 0px 0px 0px;
  }
  100% {
    height: 0px;
    margin: 0px 0px 0px;
  }
}

@keyframes open_burg_span {
  0% {
    height: 4px;
    margin: 4px 0px 0px;
  }
  50% {
    height: 0px;
    margin: 0px 0px 0px;
  }
  100% {
    height: 0px;
    margin: 0px 0px 0px;
  }
}

@-webkit-keyframes close_burg_span {
  0% {
    height: 0px;
    margin: 0px 0px;
  }
  50% {
    height: 0px;
    margin: 0px 0px;
  }
  100% {
    height: 4px;
    margin: 4px 0px;
  }
}

@-moz-keyframes close_burg_span {
  0% {
    height: 0px;
    margin: 0px 0px;
  }
  50% {
    height: 0px;
    margin: 0px 0px;
  }
  100% {
    height: 4px;
    margin: 4px 0px;
  }
}

@-o-keyframes close_burg_span {
  0% {
    height: 0px;
    margin: 0px 0px;
  }
  50% {
    height: 0px;
    margin: 0px 0px;
  }
  100% {
    height: 4px;
    margin: 4px 0px;
  }
}

@keyframes close_burg_span {
  0% {
    height: 0px;
    margin: 0px 0px;
  }
  50% {
    height: 0px;
    margin: 0px 0px;
  }
  100% {
    height: 4px;
    margin: 4px 0px;
  }
}

@-webkit-keyframes open_burg_before {
  0% {
    transform: rotate(0deg);
    margin: 0px auto 0px;
  }
  50% {
    transform: rotate(0deg);
    margin: 9px auto 0px;
    width: 32px;
  }
  100% {
    transform: rotate(45deg);
    margin: 9px auto 0px;
    width: 32px;
  }
}

@-moz-keyframes open_burg_before {
  0% {
    transform: rotate(0deg);
    margin: 0px auto 0px;
  }
  50% {
    transform: rotate(0deg);
    margin: 9px auto 0px;
    width: 32px;
  }
  100% {
    transform: rotate(45deg);
    margin: 9px auto 0px;
    width: 32px;
  }
}

@-o-keyframes open_burg_before {
  0% {
    transform: rotate(0deg);
    margin: 0px auto 0px;
  }
  50% {
    transform: rotate(0deg);
    margin: 9px auto 0px;
    width: 32px;
  }
  100% {
    transform: rotate(45deg);
    margin: 9px auto 0px;
    width: 32px;
  }
}

@keyframes open_burg_before {
  0% {
    transform: rotate(0deg);
    margin: 0px auto 0px;
  }
  50% {
    transform: rotate(0deg);
    margin: 9px auto 0px;
    width: 32px;
  }
  100% {
    transform: rotate(45deg);
    margin: 9px auto 0px;
    width: 32px;
  }
}

@-webkit-keyframes close_burg_before {
  0% {
    transform: rotate(45deg);
    margin: 9px auto 0px;
    width: 32px;
  }
  50% {
    transform: rotate(0deg);
    margin: 9px auto 0px;
    width: 32px;
  }
  100% {
    ransform: rotate(0deg);
    margin: 0px auto 0px;
  }
}

@-moz-keyframes close_burg_before {
  0% {
    transform: rotate(45deg);
    margin: 9px auto 0px;
    width: 32px;
  }
  50% {
    transform: rotate(0deg);
    margin: 9px auto 0px;
    width: 32px;
  }
  100% {
    ransform: rotate(0deg);
    margin: 0px auto 0px;
  }
}

@-o-keyframes close_burg_before {
  0% {
    transform: rotate(45deg);
    margin: 9px auto 0px;
    width: 32px;
  }
  50% {
    transform: rotate(0deg);
    margin: 9px auto 0px;
    width: 32px;
  }
  100% {
    ransform: rotate(0deg);
    margin: 0px auto 0px;
  }
}

@keyframes close_burg_before {
  0% {
    transform: rotate(45deg);
    margin: 9px auto 0px;
    width: 32px;
  }
  50% {
    transform: rotate(0deg);
    margin: 9px auto 0px;
    width: 32px;
  }
  100% {
    ransform: rotate(0deg);
    margin: 0px auto 0px;
  }
}

@-webkit-keyframes open_burg_after {
  0% {
    transform: rotate(0deg);
    margin: 0px auto 0px;
  }
  50% {
    transform: rotate(0deg);
    margin: -4px auto 0px;
    width: 32px;
  }
  100% {
    transform: rotate(-45deg);
    margin: -4px auto 0px;
    width: 32px;
  }
}

@-moz-keyframes open_burg_after {
  0% {
    transform: rotate(0deg);
    margin: 0px auto 0px;
  }
  50% {
    transform: rotate(0deg);
    margin: -4px auto 0px;
    width: 32px;
  }
  100% {
    transform: rotate(-45deg);
    margin: -4px auto 0px;
    width: 32px;
  }
}

@-o-keyframes open_burg_after {
  0% {
    transform: rotate(0deg);
    margin: 0px auto 0px;
  }
  50% {
    transform: rotate(0deg);
    margin: -4px auto 0px;
    width: 32px;
  }
  100% {
    transform: rotate(-45deg);
    margin: -4px auto 0px;
    width: 32px;
  }
}

@keyframes open_burg_after {
  0% {
    transform: rotate(0deg);
    margin: 0px auto 0px;
  }
  50% {
    transform: rotate(0deg);
    margin: -4px auto 0px;
    width: 32px;
  }
  100% {
    transform: rotate(-45deg);
    margin: -4px auto 0px;
    width: 32px;
  }
}

@-webkit-keyframes close_burg_after {
  0% {
    transform: rotate(-45deg);
    margin: -4px auto 0px;
    width: 32px;
  }
  50% {
    transform: rotate(0deg);
    margin: -4px auto 0px;
    width: 32px;
  }
  100% {
    transform: rotate(0deg);
    margin: 0px auto 0px;
  }
}

@-moz-keyframes close_burg_after {
  0% {
    transform: rotate(-45deg);
    margin: -4px auto 0px;
    width: 32px;
  }
  50% {
    transform: rotate(0deg);
    margin: -4px auto 0px;
    width: 32px;
  }
  100% {
    transform: rotate(0deg);
    margin: 0px auto 0px;
  }
}

@-o-keyframes close_burg_after {
  0% {
    transform: rotate(-45deg);
    margin: -4px auto 0px;
    width: 32px;
  }
  50% {
    transform: rotate(0deg);
    margin: -4px auto 0px;
    width: 32px;
  }
  100% {
    transform: rotate(0deg);
    margin: 0px auto 0px;
  }
}

@keyframes close_burg_after {
  0% {
    transform: rotate(-45deg);
    margin: -4px auto 0px;
    width: 32px;
  }
  50% {
    transform: rotate(0deg);
    margin: -4px auto 0px;
    width: 32px;
  }
  100% {
    transform: rotate(0deg);
    margin: 0px auto 0px;
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.container:before, .container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 768px) {
  .container {
    width: 740px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1160px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

.container-fluid:after {
  clear: both;
}

.row {
  margin-left: -10px;
  margin-right: -10px;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, .col-xs-13, .col-sm-13, .col-md-13, .col-lg-13, .col-xs-14, .col-sm-14, .col-md-14, .col-lg-14, .col-xs-15, .col-sm-15, .col-md-15, .col-lg-15, .col-xs-16, .col-sm-16, .col-md-16, .col-lg-16, .col-xs-17, .col-sm-17, .col-md-17, .col-lg-17, .col-xs-18, .col-sm-18, .col-md-18, .col-lg-18, .col-xs-19, .col-sm-19, .col-md-19, .col-lg-19, .col-xs-20, .col-sm-20, .col-md-20, .col-lg-20, .col-xs-21, .col-sm-21, .col-md-21, .col-lg-21, .col-xs-22, .col-sm-22, .col-md-22, .col-lg-22, .col-xs-23, .col-sm-23, .col-md-23, .col-lg-23, .col-xs-24, .col-sm-24, .col-md-24, .col-lg-24 {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-xs-17, .col-xs-18, .col-xs-19, .col-xs-20, .col-xs-21, .col-xs-22, .col-xs-23, .col-xs-24 {
  float: left;
}

.col-xs-1 {
  width: 4.1666666667%;
}

.col-xs-2 {
  width: 8.3333333333%;
}

.col-xs-3 {
  width: 12.5%;
}

.col-xs-4 {
  width: 16.6666666667%;
}

.col-xs-5 {
  width: 20.8333333333%;
}

.col-xs-6 {
  width: 25%;
}

.col-xs-7 {
  width: 29.1666666667%;
}

.col-xs-8 {
  width: 33.3333333333%;
}

.col-xs-9 {
  width: 37.5%;
}

.col-xs-10 {
  width: 41.6666666667%;
}

.col-xs-11 {
  width: 45.8333333333%;
}

.col-xs-12 {
  width: 50%;
}

.col-xs-13 {
  width: 54.1666666667%;
}

.col-xs-14 {
  width: 58.3333333333%;
}

.col-xs-15 {
  width: 62.5%;
}

.col-xs-16 {
  width: 66.6666666667%;
}

.col-xs-17 {
  width: 70.8333333333%;
}

.col-xs-18 {
  width: 75%;
}

.col-xs-19 {
  width: 79.1666666667%;
}

.col-xs-20 {
  width: 83.3333333333%;
}

.col-xs-21 {
  width: 87.5%;
}

.col-xs-22 {
  width: 91.6666666667%;
}

.col-xs-23 {
  width: 95.8333333333%;
}

.col-xs-24 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 4.1666666667%;
}

.col-xs-pull-2 {
  right: 8.3333333333%;
}

.col-xs-pull-3 {
  right: 12.5%;
}

.col-xs-pull-4 {
  right: 16.6666666667%;
}

.col-xs-pull-5 {
  right: 20.8333333333%;
}

.col-xs-pull-6 {
  right: 25%;
}

.col-xs-pull-7 {
  right: 29.1666666667%;
}

.col-xs-pull-8 {
  right: 33.3333333333%;
}

.col-xs-pull-9 {
  right: 37.5%;
}

.col-xs-pull-10 {
  right: 41.6666666667%;
}

.col-xs-pull-11 {
  right: 45.8333333333%;
}

.col-xs-pull-12 {
  right: 50%;
}

.col-xs-pull-13 {
  right: 54.1666666667%;
}

.col-xs-pull-14 {
  right: 58.3333333333%;
}

.col-xs-pull-15 {
  right: 62.5%;
}

.col-xs-pull-16 {
  right: 66.6666666667%;
}

.col-xs-pull-17 {
  right: 70.8333333333%;
}

.col-xs-pull-18 {
  right: 75%;
}

.col-xs-pull-19 {
  right: 79.1666666667%;
}

.col-xs-pull-20 {
  right: 83.3333333333%;
}

.col-xs-pull-21 {
  right: 87.5%;
}

.col-xs-pull-22 {
  right: 91.6666666667%;
}

.col-xs-pull-23 {
  right: 95.8333333333%;
}

.col-xs-pull-24 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 4.1666666667%;
}

.col-xs-push-2 {
  left: 8.3333333333%;
}

.col-xs-push-3 {
  left: 12.5%;
}

.col-xs-push-4 {
  left: 16.6666666667%;
}

.col-xs-push-5 {
  left: 20.8333333333%;
}

.col-xs-push-6 {
  left: 25%;
}

.col-xs-push-7 {
  left: 29.1666666667%;
}

.col-xs-push-8 {
  left: 33.3333333333%;
}

.col-xs-push-9 {
  left: 37.5%;
}

.col-xs-push-10 {
  left: 41.6666666667%;
}

.col-xs-push-11 {
  left: 45.8333333333%;
}

.col-xs-push-12 {
  left: 50%;
}

.col-xs-push-13 {
  left: 54.1666666667%;
}

.col-xs-push-14 {
  left: 58.3333333333%;
}

.col-xs-push-15 {
  left: 62.5%;
}

.col-xs-push-16 {
  left: 66.6666666667%;
}

.col-xs-push-17 {
  left: 70.8333333333%;
}

.col-xs-push-18 {
  left: 75%;
}

.col-xs-push-19 {
  left: 79.1666666667%;
}

.col-xs-push-20 {
  left: 83.3333333333%;
}

.col-xs-push-21 {
  left: 87.5%;
}

.col-xs-push-22 {
  left: 91.6666666667%;
}

.col-xs-push-23 {
  left: 95.8333333333%;
}

.col-xs-push-24 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 4.1666666667%;
}

.col-xs-offset-2 {
  margin-left: 8.3333333333%;
}

.col-xs-offset-3 {
  margin-left: 12.5%;
}

.col-xs-offset-4 {
  margin-left: 16.6666666667%;
}

.col-xs-offset-5 {
  margin-left: 20.8333333333%;
}

.col-xs-offset-6 {
  margin-left: 25%;
}

.col-xs-offset-7 {
  margin-left: 29.1666666667%;
}

.col-xs-offset-8 {
  margin-left: 33.3333333333%;
}

.col-xs-offset-9 {
  margin-left: 37.5%;
}

.col-xs-offset-10 {
  margin-left: 41.6666666667%;
}

.col-xs-offset-11 {
  margin-left: 45.8333333333%;
}

.col-xs-offset-12 {
  margin-left: 50%;
}

.col-xs-offset-13 {
  margin-left: 54.1666666667%;
}

.col-xs-offset-14 {
  margin-left: 58.3333333333%;
}

.col-xs-offset-15 {
  margin-left: 62.5%;
}

.col-xs-offset-16 {
  margin-left: 66.6666666667%;
}

.col-xs-offset-17 {
  margin-left: 70.8333333333%;
}

.col-xs-offset-18 {
  margin-left: 75%;
}

.col-xs-offset-19 {
  margin-left: 79.1666666667%;
}

.col-xs-offset-20 {
  margin-left: 83.3333333333%;
}

.col-xs-offset-21 {
  margin-left: 87.5%;
}

.col-xs-offset-22 {
  margin-left: 91.6666666667%;
}

.col-xs-offset-23 {
  margin-left: 95.8333333333%;
}

.col-xs-offset-24 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24 {
    float: left;
  }
  .col-sm-1 {
    width: 4.1666666667%;
  }
  .col-sm-2 {
    width: 8.3333333333%;
  }
  .col-sm-3 {
    width: 12.5%;
  }
  .col-sm-4 {
    width: 16.6666666667%;
  }
  .col-sm-5 {
    width: 20.8333333333%;
  }
  .col-sm-6 {
    width: 25%;
  }
  .col-sm-7 {
    width: 29.1666666667%;
  }
  .col-sm-8 {
    width: 33.3333333333%;
  }
  .col-sm-9 {
    width: 37.5%;
  }
  .col-sm-10 {
    width: 41.6666666667%;
  }
  .col-sm-11 {
    width: 45.8333333333%;
  }
  .col-sm-12 {
    width: 50%;
  }
  .col-sm-13 {
    width: 54.1666666667%;
  }
  .col-sm-14 {
    width: 58.3333333333%;
  }
  .col-sm-15 {
    width: 62.5%;
  }
  .col-sm-16 {
    width: 66.6666666667%;
  }
  .col-sm-17 {
    width: 70.8333333333%;
  }
  .col-sm-18 {
    width: 75%;
  }
  .col-sm-19 {
    width: 79.1666666667%;
  }
  .col-sm-20 {
    width: 83.3333333333%;
  }
  .col-sm-21 {
    width: 87.5%;
  }
  .col-sm-22 {
    width: 91.6666666667%;
  }
  .col-sm-23 {
    width: 95.8333333333%;
  }
  .col-sm-24 {
    width: 100%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-pull-1 {
    right: 4.1666666667%;
  }
  .col-sm-pull-2 {
    right: 8.3333333333%;
  }
  .col-sm-pull-3 {
    right: 12.5%;
  }
  .col-sm-pull-4 {
    right: 16.6666666667%;
  }
  .col-sm-pull-5 {
    right: 20.8333333333%;
  }
  .col-sm-pull-6 {
    right: 25%;
  }
  .col-sm-pull-7 {
    right: 29.1666666667%;
  }
  .col-sm-pull-8 {
    right: 33.3333333333%;
  }
  .col-sm-pull-9 {
    right: 37.5%;
  }
  .col-sm-pull-10 {
    right: 41.6666666667%;
  }
  .col-sm-pull-11 {
    right: 45.8333333333%;
  }
  .col-sm-pull-12 {
    right: 50%;
  }
  .col-sm-pull-13 {
    right: 54.1666666667%;
  }
  .col-sm-pull-14 {
    right: 58.3333333333%;
  }
  .col-sm-pull-15 {
    right: 62.5%;
  }
  .col-sm-pull-16 {
    right: 66.6666666667%;
  }
  .col-sm-pull-17 {
    right: 70.8333333333%;
  }
  .col-sm-pull-18 {
    right: 75%;
  }
  .col-sm-pull-19 {
    right: 79.1666666667%;
  }
  .col-sm-pull-20 {
    right: 83.3333333333%;
  }
  .col-sm-pull-21 {
    right: 87.5%;
  }
  .col-sm-pull-22 {
    right: 91.6666666667%;
  }
  .col-sm-pull-23 {
    right: 95.8333333333%;
  }
  .col-sm-pull-24 {
    right: 100%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-push-1 {
    left: 4.1666666667%;
  }
  .col-sm-push-2 {
    left: 8.3333333333%;
  }
  .col-sm-push-3 {
    left: 12.5%;
  }
  .col-sm-push-4 {
    left: 16.6666666667%;
  }
  .col-sm-push-5 {
    left: 20.8333333333%;
  }
  .col-sm-push-6 {
    left: 25%;
  }
  .col-sm-push-7 {
    left: 29.1666666667%;
  }
  .col-sm-push-8 {
    left: 33.3333333333%;
  }
  .col-sm-push-9 {
    left: 37.5%;
  }
  .col-sm-push-10 {
    left: 41.6666666667%;
  }
  .col-sm-push-11 {
    left: 45.8333333333%;
  }
  .col-sm-push-12 {
    left: 50%;
  }
  .col-sm-push-13 {
    left: 54.1666666667%;
  }
  .col-sm-push-14 {
    left: 58.3333333333%;
  }
  .col-sm-push-15 {
    left: 62.5%;
  }
  .col-sm-push-16 {
    left: 66.6666666667%;
  }
  .col-sm-push-17 {
    left: 70.8333333333%;
  }
  .col-sm-push-18 {
    left: 75%;
  }
  .col-sm-push-19 {
    left: 79.1666666667%;
  }
  .col-sm-push-20 {
    left: 83.3333333333%;
  }
  .col-sm-push-21 {
    left: 87.5%;
  }
  .col-sm-push-22 {
    left: 91.6666666667%;
  }
  .col-sm-push-23 {
    left: 95.8333333333%;
  }
  .col-sm-push-24 {
    left: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .col-sm-offset-1 {
    margin-left: 4.1666666667%;
  }
  .col-sm-offset-2 {
    margin-left: 8.3333333333%;
  }
  .col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .col-sm-offset-4 {
    margin-left: 16.6666666667%;
  }
  .col-sm-offset-5 {
    margin-left: 20.8333333333%;
  }
  .col-sm-offset-6 {
    margin-left: 25%;
  }
  .col-sm-offset-7 {
    margin-left: 29.1666666667%;
  }
  .col-sm-offset-8 {
    margin-left: 33.3333333333%;
  }
  .col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .col-sm-offset-10 {
    margin-left: 41.6666666667%;
  }
  .col-sm-offset-11 {
    margin-left: 45.8333333333%;
  }
  .col-sm-offset-12 {
    margin-left: 50%;
  }
  .col-sm-offset-13 {
    margin-left: 54.1666666667%;
  }
  .col-sm-offset-14 {
    margin-left: 58.3333333333%;
  }
  .col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .col-sm-offset-16 {
    margin-left: 66.6666666667%;
  }
  .col-sm-offset-17 {
    margin-left: 70.8333333333%;
  }
  .col-sm-offset-18 {
    margin-left: 75%;
  }
  .col-sm-offset-19 {
    margin-left: 79.1666666667%;
  }
  .col-sm-offset-20 {
    margin-left: 83.3333333333%;
  }
  .col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .col-sm-offset-22 {
    margin-left: 91.6666666667%;
  }
  .col-sm-offset-23 {
    margin-left: 95.8333333333%;
  }
  .col-sm-offset-24 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24 {
    float: left;
  }
  .col-md-1 {
    width: 4.1666666667%;
  }
  .col-md-2 {
    width: 8.3333333333%;
  }
  .col-md-3 {
    width: 12.5%;
  }
  .col-md-4 {
    width: 16.6666666667%;
  }
  .col-md-5 {
    width: 20.8333333333%;
  }
  .col-md-6 {
    width: 25%;
  }
  .col-md-7 {
    width: 29.1666666667%;
  }
  .col-md-8 {
    width: 33.3333333333%;
  }
  .col-md-9 {
    width: 37.5%;
  }
  .col-md-10 {
    width: 41.6666666667%;
  }
  .col-md-11 {
    width: 45.8333333333%;
  }
  .col-md-12 {
    width: 50%;
  }
  .col-md-13 {
    width: 54.1666666667%;
  }
  .col-md-14 {
    width: 58.3333333333%;
  }
  .col-md-15 {
    width: 62.5%;
  }
  .col-md-16 {
    width: 66.6666666667%;
  }
  .col-md-17 {
    width: 70.8333333333%;
  }
  .col-md-18 {
    width: 75%;
  }
  .col-md-19 {
    width: 79.1666666667%;
  }
  .col-md-20 {
    width: 83.3333333333%;
  }
  .col-md-21 {
    width: 87.5%;
  }
  .col-md-22 {
    width: 91.6666666667%;
  }
  .col-md-23 {
    width: 95.8333333333%;
  }
  .col-md-24 {
    width: 100%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-pull-1 {
    right: 4.1666666667%;
  }
  .col-md-pull-2 {
    right: 8.3333333333%;
  }
  .col-md-pull-3 {
    right: 12.5%;
  }
  .col-md-pull-4 {
    right: 16.6666666667%;
  }
  .col-md-pull-5 {
    right: 20.8333333333%;
  }
  .col-md-pull-6 {
    right: 25%;
  }
  .col-md-pull-7 {
    right: 29.1666666667%;
  }
  .col-md-pull-8 {
    right: 33.3333333333%;
  }
  .col-md-pull-9 {
    right: 37.5%;
  }
  .col-md-pull-10 {
    right: 41.6666666667%;
  }
  .col-md-pull-11 {
    right: 45.8333333333%;
  }
  .col-md-pull-12 {
    right: 50%;
  }
  .col-md-pull-13 {
    right: 54.1666666667%;
  }
  .col-md-pull-14 {
    right: 58.3333333333%;
  }
  .col-md-pull-15 {
    right: 62.5%;
  }
  .col-md-pull-16 {
    right: 66.6666666667%;
  }
  .col-md-pull-17 {
    right: 70.8333333333%;
  }
  .col-md-pull-18 {
    right: 75%;
  }
  .col-md-pull-19 {
    right: 79.1666666667%;
  }
  .col-md-pull-20 {
    right: 83.3333333333%;
  }
  .col-md-pull-21 {
    right: 87.5%;
  }
  .col-md-pull-22 {
    right: 91.6666666667%;
  }
  .col-md-pull-23 {
    right: 95.8333333333%;
  }
  .col-md-pull-24 {
    right: 100%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-push-1 {
    left: 4.1666666667%;
  }
  .col-md-push-2 {
    left: 8.3333333333%;
  }
  .col-md-push-3 {
    left: 12.5%;
  }
  .col-md-push-4 {
    left: 16.6666666667%;
  }
  .col-md-push-5 {
    left: 20.8333333333%;
  }
  .col-md-push-6 {
    left: 25%;
  }
  .col-md-push-7 {
    left: 29.1666666667%;
  }
  .col-md-push-8 {
    left: 33.3333333333%;
  }
  .col-md-push-9 {
    left: 37.5%;
  }
  .col-md-push-10 {
    left: 41.6666666667%;
  }
  .col-md-push-11 {
    left: 45.8333333333%;
  }
  .col-md-push-12 {
    left: 50%;
  }
  .col-md-push-13 {
    left: 54.1666666667%;
  }
  .col-md-push-14 {
    left: 58.3333333333%;
  }
  .col-md-push-15 {
    left: 62.5%;
  }
  .col-md-push-16 {
    left: 66.6666666667%;
  }
  .col-md-push-17 {
    left: 70.8333333333%;
  }
  .col-md-push-18 {
    left: 75%;
  }
  .col-md-push-19 {
    left: 79.1666666667%;
  }
  .col-md-push-20 {
    left: 83.3333333333%;
  }
  .col-md-push-21 {
    left: 87.5%;
  }
  .col-md-push-22 {
    left: 91.6666666667%;
  }
  .col-md-push-23 {
    left: 95.8333333333%;
  }
  .col-md-push-24 {
    left: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  .col-md-offset-1 {
    margin-left: 4.1666666667%;
  }
  .col-md-offset-2 {
    margin-left: 8.3333333333%;
  }
  .col-md-offset-3 {
    margin-left: 12.5%;
  }
  .col-md-offset-4 {
    margin-left: 16.6666666667%;
  }
  .col-md-offset-5 {
    margin-left: 20.8333333333%;
  }
  .col-md-offset-6 {
    margin-left: 25%;
  }
  .col-md-offset-7 {
    margin-left: 29.1666666667%;
  }
  .col-md-offset-8 {
    margin-left: 33.3333333333%;
  }
  .col-md-offset-9 {
    margin-left: 37.5%;
  }
  .col-md-offset-10 {
    margin-left: 41.6666666667%;
  }
  .col-md-offset-11 {
    margin-left: 45.8333333333%;
  }
  .col-md-offset-12 {
    margin-left: 50%;
  }
  .col-md-offset-13 {
    margin-left: 54.1666666667%;
  }
  .col-md-offset-14 {
    margin-left: 58.3333333333%;
  }
  .col-md-offset-15 {
    margin-left: 62.5%;
  }
  .col-md-offset-16 {
    margin-left: 66.6666666667%;
  }
  .col-md-offset-17 {
    margin-left: 70.8333333333%;
  }
  .col-md-offset-18 {
    margin-left: 75%;
  }
  .col-md-offset-19 {
    margin-left: 79.1666666667%;
  }
  .col-md-offset-20 {
    margin-left: 83.3333333333%;
  }
  .col-md-offset-21 {
    margin-left: 87.5%;
  }
  .col-md-offset-22 {
    margin-left: 91.6666666667%;
  }
  .col-md-offset-23 {
    margin-left: 95.8333333333%;
  }
  .col-md-offset-24 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24 {
    float: left;
  }
  .col-lg-1 {
    width: 4.1666666667%;
  }
  .col-lg-2 {
    width: 8.3333333333%;
  }
  .col-lg-3 {
    width: 12.5%;
  }
  .col-lg-4 {
    width: 16.6666666667%;
  }
  .col-lg-5 {
    width: 20.8333333333%;
  }
  .col-lg-6 {
    width: 25%;
  }
  .col-lg-7 {
    width: 29.1666666667%;
  }
  .col-lg-8 {
    width: 33.3333333333%;
  }
  .col-lg-9 {
    width: 37.5%;
  }
  .col-lg-10 {
    width: 41.6666666667%;
  }
  .col-lg-11 {
    width: 45.8333333333%;
  }
  .col-lg-12 {
    width: 50%;
  }
  .col-lg-13 {
    width: 54.1666666667%;
  }
  .col-lg-14 {
    width: 58.3333333333%;
  }
  .col-lg-15 {
    width: 62.5%;
  }
  .col-lg-16 {
    width: 66.6666666667%;
  }
  .col-lg-17 {
    width: 70.8333333333%;
  }
  .col-lg-18 {
    width: 75%;
  }
  .col-lg-19 {
    width: 79.1666666667%;
  }
  .col-lg-20 {
    width: 83.3333333333%;
  }
  .col-lg-21 {
    width: 87.5%;
  }
  .col-lg-22 {
    width: 91.6666666667%;
  }
  .col-lg-23 {
    width: 95.8333333333%;
  }
  .col-lg-24 {
    width: 100%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-pull-1 {
    right: 4.1666666667%;
  }
  .col-lg-pull-2 {
    right: 8.3333333333%;
  }
  .col-lg-pull-3 {
    right: 12.5%;
  }
  .col-lg-pull-4 {
    right: 16.6666666667%;
  }
  .col-lg-pull-5 {
    right: 20.8333333333%;
  }
  .col-lg-pull-6 {
    right: 25%;
  }
  .col-lg-pull-7 {
    right: 29.1666666667%;
  }
  .col-lg-pull-8 {
    right: 33.3333333333%;
  }
  .col-lg-pull-9 {
    right: 37.5%;
  }
  .col-lg-pull-10 {
    right: 41.6666666667%;
  }
  .col-lg-pull-11 {
    right: 45.8333333333%;
  }
  .col-lg-pull-12 {
    right: 50%;
  }
  .col-lg-pull-13 {
    right: 54.1666666667%;
  }
  .col-lg-pull-14 {
    right: 58.3333333333%;
  }
  .col-lg-pull-15 {
    right: 62.5%;
  }
  .col-lg-pull-16 {
    right: 66.6666666667%;
  }
  .col-lg-pull-17 {
    right: 70.8333333333%;
  }
  .col-lg-pull-18 {
    right: 75%;
  }
  .col-lg-pull-19 {
    right: 79.1666666667%;
  }
  .col-lg-pull-20 {
    right: 83.3333333333%;
  }
  .col-lg-pull-21 {
    right: 87.5%;
  }
  .col-lg-pull-22 {
    right: 91.6666666667%;
  }
  .col-lg-pull-23 {
    right: 95.8333333333%;
  }
  .col-lg-pull-24 {
    right: 100%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-push-1 {
    left: 4.1666666667%;
  }
  .col-lg-push-2 {
    left: 8.3333333333%;
  }
  .col-lg-push-3 {
    left: 12.5%;
  }
  .col-lg-push-4 {
    left: 16.6666666667%;
  }
  .col-lg-push-5 {
    left: 20.8333333333%;
  }
  .col-lg-push-6 {
    left: 25%;
  }
  .col-lg-push-7 {
    left: 29.1666666667%;
  }
  .col-lg-push-8 {
    left: 33.3333333333%;
  }
  .col-lg-push-9 {
    left: 37.5%;
  }
  .col-lg-push-10 {
    left: 41.6666666667%;
  }
  .col-lg-push-11 {
    left: 45.8333333333%;
  }
  .col-lg-push-12 {
    left: 50%;
  }
  .col-lg-push-13 {
    left: 54.1666666667%;
  }
  .col-lg-push-14 {
    left: 58.3333333333%;
  }
  .col-lg-push-15 {
    left: 62.5%;
  }
  .col-lg-push-16 {
    left: 66.6666666667%;
  }
  .col-lg-push-17 {
    left: 70.8333333333%;
  }
  .col-lg-push-18 {
    left: 75%;
  }
  .col-lg-push-19 {
    left: 79.1666666667%;
  }
  .col-lg-push-20 {
    left: 83.3333333333%;
  }
  .col-lg-push-21 {
    left: 87.5%;
  }
  .col-lg-push-22 {
    left: 91.6666666667%;
  }
  .col-lg-push-23 {
    left: 95.8333333333%;
  }
  .col-lg-push-24 {
    left: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  .col-lg-offset-1 {
    margin-left: 4.1666666667%;
  }
  .col-lg-offset-2 {
    margin-left: 8.3333333333%;
  }
  .col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .col-lg-offset-4 {
    margin-left: 16.6666666667%;
  }
  .col-lg-offset-5 {
    margin-left: 20.8333333333%;
  }
  .col-lg-offset-6 {
    margin-left: 25%;
  }
  .col-lg-offset-7 {
    margin-left: 29.1666666667%;
  }
  .col-lg-offset-8 {
    margin-left: 33.3333333333%;
  }
  .col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .col-lg-offset-10 {
    margin-left: 41.6666666667%;
  }
  .col-lg-offset-11 {
    margin-left: 45.8333333333%;
  }
  .col-lg-offset-12 {
    margin-left: 50%;
  }
  .col-lg-offset-13 {
    margin-left: 54.1666666667%;
  }
  .col-lg-offset-14 {
    margin-left: 58.3333333333%;
  }
  .col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .col-lg-offset-16 {
    margin-left: 66.6666666667%;
  }
  .col-lg-offset-17 {
    margin-left: 70.8333333333%;
  }
  .col-lg-offset-18 {
    margin-left: 75%;
  }
  .col-lg-offset-19 {
    margin-left: 79.1666666667%;
  }
  .col-lg-offset-20 {
    margin-left: 83.3333333333%;
  }
  .col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .col-lg-offset-22 {
    margin-left: 91.6666666667%;
  }
  .col-lg-offset-23 {
    margin-left: 95.8333333333%;
  }
  .col-lg-offset-24 {
    margin-left: 100%;
  }
}

@-ms-viewport {
  width: device-width;
}

.visible-xs {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-md {
  display: none !important;
}

.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// FONTS */
/* font NovelSansPro */
@font-face {
  font-family: 'Regular';
  src: url("../../fonts/32D778_6_0.woff2") format("woff2"), url("../../fonts/32D778_6_0.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bold';
  src: url("../../fonts/32D778_A_0.woff2") format("woff2"), url("../../fonts/32D778_A_0.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SemiBold';
  src: url("../../fonts/32D778_5_0.woff2") format("woff2"), url("../../fonts/32D778_5_0.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*@font-face {
    font-family: 'ExtraBold';
    src:url('../../fonts/32D778_7_0.woff2') format('woff2'),
        url('../../fonts/32D778_7_0.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/
@font-face {
  font-family: 'icons';
  src: url("../../fonts/icons.woff2") format("woff2"), url("../../fonts/icons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

icon, .icon {
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
}

icon:before, .icon:before {
  font-family: 'icons';
  speak: none;
  margin: 0px 5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
}

icon.acucar:before, .icon.acucar:before {
  content: '\e800';
}

icon.alcool:before, .icon.alcool:before {
  content: '\e801';
}

icon.close:before, .icon.close:before {
  content: '\e802';
}

icon.download:before, .icon.download:before {
  content: '\e803';
}

icon.estrela:before, .icon.estrela:before {
  content: '\e804';
}

icon.fav_cheio:before, .icon.fav_cheio:before {
  content: '\e805';
}

icon.fav_vazio:before, .icon.fav_vazio:before {
  content: '\e806';
}

icon.info:before, .icon.info:before {
  content: '\e807';
}

icon.link:before, .icon.link:before {
  content: '\e808';
}

icon.seta1_baixo:before, .icon.seta1_baixo:before {
  content: '\e809';
}

icon.seta1_cima:before, .icon.seta1_cima:before {
  content: '\e80a';
}

icon.seta2_baixo:before, .icon.seta2_baixo:before {
  content: '\e80b';
}

icon.seta2_cima:before, .icon.seta2_cima:before {
  content: '\e80c';
}

icon.seta2_dir:before, .icon.seta2_dir:before {
  content: '\e80d';
}

icon.seta2_esq:before, .icon.seta2_esq:before {
  content: '\e80e';
}

icon.valor_energetico:before, .icon.valor_energetico:before {
  content: '\e80f';
}

icon.vegan:before, .icon.vegan:before {
  content: '\e810';
}

icon.alergenicos:before, .icon.alergenicos:before {
  content: '\e811';
}

icon.gluten:before, .icon.gluten:before {
  content: '\e812';
}

icon.vegetarian:before, .icon.vegetarian:before {
  content: '\e813';
}

icon.clear:before, .icon.clear:before {
  content: '\e814';
}

icon.bola:before, .icon.bola:before {
  content: '●';
}

.anim-spin:before {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// BODY */
html, body, div, pre, form, fieldset, input, h1, h2, h3, h4, h5, h6, p, textarea, ul, ol, li, dl, dt, dd, blockquote, th, td,
abbr, article, aside, audio, canvas, details, figcaption, figure, footer, header, hgroup,
mark, menu, meter, nav, output, progress, section, summary, time, video {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code {
  font-style: normal;
  font-weight: normal;
}

ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
}

a {
  outline: none;
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

a:hover {
  color: #000;
}

a img {
  border: none;
}

:focus {
  outline: none;
}

/* For Chrome */
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body, html {
  width: 100%;
  height: 100%;
  font-family: 'Regular', sans-serif;
  font-size: 100%;
  color: #414141;
  background: #f6f6ee;
}

main {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 184px;
  min-height: -moz-calc(100% - 160px);
  min-height: -webkit-calc(100% - 160px);
  min-height: calc(100% - 160px);
}

[v-cloak] {
  display: none;
}

body.no-scroll {
  overflow: hidden;
}

section {
  position: relative;
  width: 100%;
}

.middle {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}

.row {
  font-size: 0;
}

@media (max-width: 767px) {
  main {
    padding-top: 60px;
  }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// HEADER */
header {
  position: absolute;
  background-color: #fff;
  width: 100%;
  z-index: 15;
}

header .billhead {
  position: relative;
  width: 100%;
  height: 114px;
  border-bottom: 1px solid #e0dddc;
  z-index: 4;
}

header .billhead .hamburger {
  float: left;
  position: relative;
  margin: 20px 0px 0px 20px;
  cursor: pointer;
  display: none;
  z-index: 1;
}

header .billhead .hamburger span {
  display: block;
  width: 32px;
  height: 4px;
  margin: 4px 0px;
  background-color: #b15533;
  -moz-animation: close_burg_span 250ms forwards ease-in-out;
  -o-animation: close_burg_span 250ms forwards ease-in-out;
  -webkit-animation: close_burg_span 250ms forwards ease-in-out;
  animation: close_burg_span 250ms forwards ease-in-out;
  border-radius: 5px;
}

header .billhead .hamburger:before {
  content: '';
  display: block;
  width: 32px;
  height: 4px;
  background-color: #b15533;
  -moz-animation: close_burg_before 250ms forwards ease-in-out;
  -o-animation: close_burg_before 250ms forwards ease-in-out;
  -webkit-animation: close_burg_before 250ms forwards ease-in-out;
  animation: close_burg_before 250ms forwards ease-in-out;
  border-radius: 5px;
}

header .billhead .hamburger:after {
  content: '';
  display: block;
  width: 32px;
  height: 4px;
  background-color: #b15533;
  -moz-animation: close_burg_after 250ms forwards ease-in-out;
  -o-animation: close_burg_after 250ms forwards ease-in-out;
  -webkit-animation: close_burg_after 250ms forwards ease-in-out;
  animation: close_burg_after 250ms forwards ease-in-out;
  border-radius: 5px;
}

header .billhead .hamburger.open span {
  -moz-animation: open_burg_span 250ms forwards ease-in-out;
  -o-animation: open_burg_span 250ms forwards ease-in-out;
  -webkit-animation: open_burg_span 250ms forwards ease-in-out;
  animation: open_burg_span 250ms forwards ease-in-out;
  height: 0px;
  margin: 0px 0px;
}

header .billhead .hamburger.open.show {
  opacity: 1;
  filter: alpha(opacity=100);
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

header .billhead .hamburger.open:before {
  -moz-animation: open_burg_before 250ms forwards ease-in-out;
  -o-animation: open_burg_before 250ms forwards ease-in-out;
  -webkit-animation: open_burg_before 250ms forwards ease-in-out;
  animation: open_burg_before 250ms forwards ease-in-out;
  transform: rotate(45deg);
  margin: 9px auto 0px;
  width: 32px;
}

header .billhead .hamburger.open:after {
  -moz-animation: open_burg_after 250ms forwards ease-in-out;
  -o-animation: open_burg_after 250ms forwards ease-in-out;
  -webkit-animation: open_burg_after 250ms forwards ease-in-out;
  animation: open_burg_after 250ms forwards ease-in-out;
  transform: rotate(-45deg);
  margin: -4px auto 0px;
  width: 32px;
}

header .billhead .logo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 139px;
  height: 88px;
  margin: auto;
  background-image: url("../../img/logo-sogrape.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

header .billhead .idiom {
  position: absolute;
  top: 26px;
  right: 20px;
}

header .billhead .idiom a {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-left: 6px;
  border-radius: 100%;
  border: 1px solid #b15533;
  color: #b15533;
  font-family: 'SemiBold';
  font-size: 10px;
  line-height: 32px;
  text-align: center;
}

header .billhead .idiom a.active {
  background-color: #b15533;
  color: #fff;
}

header nav {
  position: relative;
  width: 100%;
  height: 70px;
  z-index: 2;
}

header nav .menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

header nav .menu .link {
  position: relative;
  display: table;
  min-height: 34px;
  margin-top: 16px;
}

header nav .menu .link:hover a, header nav .menu .link.active a {
  color: #b15533;
}

header nav .menu .link:hover:after, header nav .menu .link.active:after {
  opacity: 1;
}

header nav .menu .link:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  width: 8px;
  height: 8px;
  margin: auto;
  border-radius: 100%;
  background-color: #b15533;
  opacity: 0;
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

header nav .menu a {
  position: relative;
  display: table-cell;
  padding: 0px 16px;
  font-family: 'SemiBold';
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  word-spacing: 1px;
  vertical-align: middle;
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

header nav .menu a br {
  display: block;
}

@media (max-width: 991px) {
  header nav .menu a {
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  header {
    position: fixed;
  }
  header .billhead {
    height: 60px;
  }
  header .billhead .hamburger {
    display: block;
  }
  header .billhead .logo {
    width: 76px;
    height: 48px;
  }
  header .billhead .idiom {
    top: 14px;
  }
  header nav {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    padding-top: 60px;
  }
  header nav.v-enter, header nav.v-leave-active {
    height: 0%;
    -moz-transition: 0ms;
    -o-transition: 0ms;
    -webkit-transition: 0ms;
    transition: 0ms;
  }
  header nav.v-enter .menu .link, header nav.v-leave-active .menu .link {
    height: 0%;
  }
  header nav.v-leave-active {
    height: 100%;
    -moz-transition: 250ms ease-in-out;
    -o-transition: 250ms ease-in-out;
    -webkit-transition: 250ms ease-in-out;
    transition: 250ms ease-in-out;
  }
  header nav.v-leave-active .menu .link {
    height: 0%;
  }
  header nav .menu {
    height: 100%;
    flex-wrap: wrap;
    padding: 0;
    overflow: hidden;
    -moz-transition: 250ms ease-in-out;
    -o-transition: 250ms ease-in-out;
    -webkit-transition: 250ms ease-in-out;
    transition: 250ms ease-in-out;
  }
  header nav .menu .link {
    display: flex;
    width: 100%;
    height: 20%;
    margin-top: 0;
    background-color: #fff;
    border-bottom: 1px solid #e0dddc;
    overflow: hidden;
    align-items: center;
    -moz-transition: 250ms ease-in-out;
    -o-transition: 250ms ease-in-out;
    -webkit-transition: 250ms ease-in-out;
    transition: 250ms ease-in-out;
  }
  header nav .menu a {
    width: 100%;
    padding: 0;
    font-size: 18px;
  }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// MAIN */
.banner {
  width: 100%;
  height: 460px;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.banner .info {
  position: absolute;
  bottom: 0;
  width: 46%;
  padding: 0px 0px 40px 20px;
}

.banner .info .title {
  display: block;
  color: #fff;
  font-family: 'Bold';
  font-size: 34px;
  text-transform: uppercase;
  line-height: 125%;
}

.banner .info .text {
  display: block;
  color: #fff;
  font-family: 'SemiBold';
  font-size: 20px;
  line-height: 125%;
}

@media (max-width: 767px) {
  .banner {
    height: 240px;
  }
  .banner .info {
    width: 100%;
    padding: 0px 0px 20px 20px;
  }
  .banner .info .title {
    font-size: 28px;
  }
  .banner .info .text {
    font-size: 18px;
  }
}

.loading {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.loading:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-flex;
  width: 48px;
  height: 48px;
  border: 5px solid transparent;
  border-top: 5px solid #b15533;
  border-radius: 50%;
  vertical-align: middle;
}

.loading:after {
  content: '';
  display: inline-flex;
  width: 44px;
  height: 44px;
  border: 1px solid #eee;
  border-radius: 50%;
  vertical-align: middle;
}

/*/////////////////////////////////////////////////////////////////////////////////////////// FORMS */
.form-group {
  float: left;
  position: relative;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}

.form-group input, .form-group select {
  display: block;
  position: relative;
  width: 100%;
  padding: 10px;
  height: 52px;
  border: 0;
  background-color: #fff;
  color: #414141;
  font-family: 'Regular';
  font-size: 13px;
  box-shadow: none;
  outline: 0;
}

.form-group input:-webkit-autofill, .form-group select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.form-group input::-webkit-input-placeholder, .form-group select::-webkit-input-placeholder {
  color: #414141;
}

.form-group input::-moz-placeholder, .form-group select::-moz-placeholder {
  color: #414141;
}

.form-group input:-ms-input-placeholder, .form-group select:-ms-input-placeholder {
  color: #414141;
}

.form-group input[disabled], .form-group select[disabled] {
  background-color: #efefef;
}

.form-group input[type=number]::-webkit-inner-spin-button, .form-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

.form-group select::-ms-expand {
  display: none;
}

.form-group select option {
  color: #414141;
}

.form-group select.empty {
  color: #414141;
}

.form-group:before {
  position: absolute;
  top: 20px;
  right: 10px;
  color: #b15533;
  font-family: 'icons';
  z-index: 10;
}

.form-group.select select {
  padding: 0px 30px 0px 20px;
}

.form-group.select:before {
  content: '\e809';
  font-size: 12px;
}

.form-group.search input {
  padding: 0px 30px 0px 20px;
}

.form-group.search:before {
  content: '\e857';
  font-size: 12px;
}

.form-group .dropdown {
  display: block;
  position: relative;
  width: 100%;
  height: 52px;
  border: 0;
  background-color: #fff;
  color: #414141;
  font-family: 'Regular';
  font-size: 13px;
  box-shadow: none;
  outline: 0;
}

.form-group .dropdown .dropdown-toggle {
  position: relative;
  width: 100%;
  height: 100%;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group .dropdown .dropdown-toggle:before {
  position: absolute;
  content: '\e809';
  top: 20px;
  right: 10px;
  color: #b15533;
  font-size: 12px;
  font-family: 'icons';
  z-index: 10;
}

.form-group .dropdown .selected-tag {
  width: 100%;
  height: auto;
  margin: auto;
  padding: 0px 40px 0px 20px;
  border: 0;
  background: 0;
  line-height: 110%;
  align-self: left;
}

.form-group .dropdown input[type=search], .form-group .dropdown input[type=search]:focus {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  padding: 0px 40px 0px 20px;
  color: #414141;
  font-family: 'Regular';
  font-size: 13px;
  z-index: 1;
}

.form-group .dropdown .open-indicator {
  display: none;
}

.form-group .dropdown .spinner {
  top: 12px;
  right: 4px;
  width: 24px;
  height: 24px;
  border-top: 3px solid rgba(99, 99, 99, 0.1);
  border-right: 3px solid rgba(99, 99, 99, 0.1);
  border-bottom: 3px solid rgba(99, 99, 99, 0.1);
  border-left: 3px solid rgba(60, 60, 60, 0.45);
  opacity: 1;
}

.form-group .dropdown .dropdown-menu {
  overflow-x: hidden;
  border: none;
}

.form-group .dropdown .dropdown-menu li {
  width: 100%;
  margin: 0;
  min-height: 16px !important;
}

.form-group .dropdown .dropdown-menu li a {
  width: 100%;
  height: auto;
  padding: 5px 20px;
  line-height: 20px;
  color: #414141 !important;
  font-family: 'Regular';
  font-size: 13px !important;
  white-space: inherit !important;
  text-decoration: none !important;
}

.form-group .dropdown .dropdown-menu .active a {
  background-color: #f0f0f0;
}

.form-group .dropdown .dropdown-menu .highlight a {
  color: #fff !important;
  background-color: #b15533;
}

.box-finder .form-group .dropdown {
  font-family: 'SemiBold';
  border: 1px solid rgba(60, 60, 60, 0.5);
}

.box-finder .form-group .dropdown input[type=search]:focus {
  background-color: #fff;
}

.bar-links .form-group .dropdown .selected-tag, .pages .form-group .dropdown .selected-tag {
  text-transform: uppercase;
}

.bar-links .form-group .dropdown a, .pages .form-group .dropdown a {
  text-transform: uppercase;
}

/*/////////////////////////////////////////////////////////////////////////////////////////// FOOTER */
footer .billhead {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 90px;
  border-top: 1px solid #b15533;
  background-color: #fff;
  font-size: 0;
  align-items: center;
  padding: 5px 0px;
}

footer .billhead .links {
  order: 1;
  display: inline-block;
  width: 80%;
  padding: 0px 0px 8px 35px;
  text-align: left;
  align-self: flex-end;
  vertical-align: bottom;
}

footer .billhead .links p {
  display: inline-block;
  color: #656565;
  font-family: 'SemiBold';
  font-size: 11px;
  text-transform: uppercase;
}

footer .billhead .links p span {
  padding: 0px 5px;
}

footer .billhead .links p span a {
  padding: 0px 5px;
  color: #656565;
}

footer .billhead .logo {
  order: 2;
  display: inline-block;
  width: 20%;
  padding: 0px 40px 0px 0px;
  text-align: right;
}

footer .billhead .logo a {
  display: inline-block;
  width: 84px;
  height: 60px;
  background-image: url("../../img/logo-sogrape_negocio.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

footer .baseboard {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 70px;
  padding: 20px 0px;
  background-color: #eeeeee;
  align-items: center;
  justify-content: center;
  text-align: center;
}

footer .baseboard span {
  order: 1;
  display: inline-block;
  margin: 0px 10px 0px;
  color: #656565;
  font-family: 'Bold';
  font-size: 16px;
  text-transform: uppercase;
  vertical-align: super;
}

footer .baseboard .logo-winmoderation {
  order: 2;
  display: inline-block;
  width: 131px;
  height: 29px;
  margin: 0px 10px;
  background-image: url("../../img/logo-winmoderation.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 767px) {
  footer .billhead {
    align-content: center;
    padding: 0px 0px;
  }
  footer .billhead .links {
    width: 70%;
    padding: 15px 0px 0px 0px;
    text-align: center;
    align-self: auto;
    vertical-align: center;
  }
  footer .billhead .links p {
    text-align: center;
    font-size: 9px;
  }
  footer .billhead .links p span {
    display: block;
  }
  footer .billhead .links p span a {
    display: inline-block;
  }
  footer .billhead .logo {
    width: 30%;
    padding: 0px 10px 0px 0px;
    text-align: center;
  }
  footer .billhead .logo a {
    width: 78px;
    height: 56px;
  }
  footer .baseboard span {
    font-size: 12px;
  }
}

@media (max-width: 479px) {
  footer .baseboard {
    align-content: center;
    padding: 0px 0px;
  }
  footer .baseboard span {
    order: 3;
    width: 100%;
    margin: 5px 10px 0px;
  }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// AGE-CHECK */
.age-check .billhead .hamburger {
  display: none;
}

.age-check nav {
  display: none;
}

.age-check main {
  background-color: #b15533;
}

.age-check .area-age {
  display: table;
  width: 720px;
  margin: 0px auto 60px;
  color: #fff;
  text-align: center;
}

.age-check .area-age h2 {
  display: block;
  font-family: 'Bold';
  font-size: 46px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.age-check .area-age h3 {
  display: block;
  font-family: 'SemiBold';
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 120%;
}

.age-check .area-age h4 {
  display: block;
  font-family: 'Regular';
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 120%;
}

.age-check .area-age h5 {
  display: block;
  font-family: 'Regular';
  font-size: 11px;
  line-height: 120%;
}

.age-check .area-age form {
  display: block;
  margin-top: 10px;
}

.age-check .area-age form .age, .age-check .area-age form .contry {
  float: left;
  width: 50%;
  padding: 0px 10px;
}

.age-check .area-age form .age label, .age-check .area-age form .contry label {
  float: left;
  width: 100%;
  color: #fff;
  font-family: 'SemiBold';
  font-size: 20px;
  margin-bottom: 10px;
}

.age-check .area-age form .form-group.day, .age-check .area-age form .form-group.month, .age-check .area-age form .form-group.year {
  float: left;
  width: 33.33%;
  padding: 0px 10px;
}

.age-check .area-age form .form-group.nation {
  float: left;
  width: 100%;
  padding: 0px 10px;
}

.age-check .area-age form .form-group:before {
  right: 20px;
}

.age-check .area-age form .form-group select option:first-child {
  display: none;
}

.age-check .area-age form .remeber {
  float: left;
  width: 100%;
  padding: 0px 10px;
}

.age-check .area-age form .remeber .form-group {
  text-align: center;
}

.age-check .area-age form .remeber input {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  vertical-align: middle;
  cursor: pointer;
}

.age-check .area-age form button {
  display: block;
  width: 190px;
  height: 42px;
  margin: 0px auto 20px;
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
  font-family: 'SemiBold';
  font-size: 17px;
  text-transform: uppercase;
  cursor: pointer;
}

.age-check .area-age a {
  color: #fff;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .age-check .area-age {
    width: 100%;
    max-width: 480px;
    margin: 10px auto 20px;
  }
  .age-check .area-age h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  .age-check .area-age h3 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .age-check .area-age h4 {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .age-check .area-age form .age, .age-check .area-age form .contry {
    width: 100%;
    padding: 0px;
  }
  .age-check .area-age form .age label, .age-check .area-age form .contry label {
    font-size: 18px;
  }
  .age-check .area-age form .form-group.day, .age-check .area-age form .form-group.month, .age-check .area-age form .form-group.year {
    float: left;
    width: 33.33%;
    padding: 0px 10px;
  }
  .age-check .area-age form .form-group.nation {
    float: left;
    width: 100%;
    padding: 0px 10px;
  }
  .age-check .area-age form .remeber {
    float: left;
    width: 100%;
    padding: 0px 10px;
  }
  .age-check .area-age form .remeber .form-group {
    text-align: center;
  }
  .age-check .area-age form .remeber input {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    vertical-align: middle;
    cursor: pointer;
  }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// HOME */
.home-top {
  width: 100%;
  height: auto;
  background-color: #b15533;
  padding-bottom: 30px;
  box-sizing: content-box;
}

.home-top:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 30px;
  background-color: #fff;
  z-index: 0;
}

.home-top .box-slider {
  position: relative;
  height: 500px;
  background-color: #fff;
  z-index: 1;
}

.home-top .box-slider .slider {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.home-top .box-slider .slider .item {
  position: relative;
  height: 500px;
  border: none;
}

.home-top .box-slider .slider .item .imag {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
}

.home-top .box-slider .slider .item .box {
  position: absolute;
  bottom: 10px;
  left: 40px;
  max-width: 75%;
  -moz-transition: 250ms ease-in-out 250ms;
  -o-transition: 250ms ease-in-out 250ms;
  -webkit-transition: 250ms ease-in-out 250ms;
  transition: 250ms ease-in-out 250ms;
  z-index: 3;
}

.home-top .box-slider .slider .item .box .title {
  display: block;
  color: #fff;
  font-family: 'Bold';
  font-size: 34px;
  text-transform: uppercase;
  line-height: 100%;
}

.home-top .box-slider .slider .item .box .subtitle {
  display: block;
  margin: 0px 0px 10px;
  color: #fff;
  font-family: 'SemiBold';
  font-size: 20px;
  color: #fff;
  line-height: 110%;
}

.home-top .box-slider .slider .item .box .link {
  display: table;
  color: #b15533;
  font-family: 'Bold';
  font-size: 15px;
  text-transform: uppercase;
}

.home-top .box-slider .slider .item .box .link icon {
  display: inline-block;
  width: auto;
  max-width: 0px;
  vertical-align: bottom;
  overflow: hidden;
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.home-top .box-slider .slider .item .box .link:hover icon {
  max-width: 50px;
}

.home-top .box-slider .slider .item:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  z-index: 2;
  background: transparent;
  background: -moz-linear-gradient(top, transparent 50%, rgba(0, 0, 0, 0.25) 75%, rgba(1, 12, 18, 0.5) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(50%, transparent), color-stop(75%, rgba(0, 0, 0, 0.25)), color-stop(100%, rgba(1, 12, 18, 0.5)));
  background: -webkit-linear-gradient(top, transparent 50%, rgba(0, 0, 0, 0.25) 75%, rgba(1, 12, 18, 0.5) 100%);
  background: -o-linear-gradient(top, transparent 50%, rgba(0, 0, 0, 0.25) 75%, rgba(1, 12, 18, 0.5) 100%);
  background: -ms-linear-gradient(top, transparent 50%, rgba(0, 0, 0, 0.25) 75%, rgba(1, 12, 18, 0.5) 100%);
  background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.25) 75%, rgba(1, 12, 18, 0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#010c12', GradientType=0 );
}

.home-top .box-slider .slider .item.slick-current .box {
  bottom: 50px;
}

.home-top .box-slider .slider-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50px;
  width: 50px;
  height: 114px;
  margin: auto;
  z-index: 1;
}

.home-top .box-slider .slider-arrow .slick-arrow {
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;
  border: 1px solid #fff;
  border-radius: 100%;
  background: transparent;
  outline: none;
  color: transparent;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
}

.home-top .box-slider .slider-arrow .slick-prev {
  top: 0;
}

.home-top .box-slider .slider-arrow .slick-prev:before {
  content: '\e80c';
  color: #fff;
  font-family: 'icons';
  font-size: 18px;
  line-height: 50px;
}

.home-top .box-slider .slider-arrow .slick-next {
  bottom: 0;
}

.home-top .box-slider .slider-arrow .slick-next:before {
  content: '\e80b';
  color: #fff;
  font-family: 'icons';
  font-size: 18px;
  line-height: 50px;
}

.home-top .box-slider .slider-arrow #slick-conter {
  position: absolute;
  bottom: -2.5px;
  left: -2.5px;
  z-index: 1;
  cursor: pointer;
  display: none;
}

.home-top .box-finder {
  position: relative;
  height: 500px;
  background-color: #fff;
  z-index: 1;
}

.home-top .box-finder .imag {
  width: 100%;
  height: 270px;
  background-image: url("../../img/finder.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home-top .box-finder .info {
  position: relative;
  display: table;
  width: 100%;
  height: 230px;
  padding: 60px 20px 20px;
  background-color: #fff;
  text-align: center;
}

.home-top .box-finder .info .icon {
  position: absolute;
  left: 0;
  right: 0;
  top: -40px;
  width: 80px;
  height: 80px;
  margin: auto;
  border-radius: 100%;
  background-color: #b15533;
  background-image: url("../../img/tabela.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-size: 80%;
}

.home-top .box-finder .info .more {
  display: block;
  color: #b15533;
  font-family: 'Bold';
  font-size: 15px;
  text-transform: uppercase;
}

.home-top .box-finder .info .title {
  display: block;
  margin-bottom: 10px;
  color: #414141;
  font-family: 'Bold';
  font-size: 16px;
  text-transform: uppercase;
}

.home-top .box-finder .info .form-group {
  margin-bottom: 0px;
}

.home-top .box-finder .info .form-group select {
  border: 1px solid #bcbcbc;
}

.home .boxes .btn-back {
  display: none;
}

@media (max-width: 991px) {
  .home-top .box-slider .slider .item .box .title {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .home-top .col-slider {
    padding: 0;
  }
  .home-top .col-finder {
    margin: auto;
    max-width: 460px;
  }
  .home-top .box-slider {
    height: 420px;
  }
  .home-top .box-slider .slider .item {
    height: 420px;
  }
  .home-top .box-slider .slider .item .box {
    left: 20px;
  }
  .home-top .box-slider .slider-arrow {
    left: 20px;
    display: none;
  }
  .home-top .box-slider .slider-arrow .slick-prev:before {
    display: none;
  }
  .home-top .box-slider .slider-arrow .slick-next {
    display: none !important;
  }
  .home-top .box-slider .slider-arrow #slick-conter {
    top: -2px;
    bottom: inherit;
  }
  .home-top .box-finder {
    height: auto;
    margin: 20px 0px 0px;
  }
  .home-top .box-finder .imag {
    height: 180px;
  }
  .home-top .box-finder .info {
    height: auto;
  }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// MODERATION */
.bar-links {
  position: relative;
  width: 100%;
  height: 112px;
  background-color: #b15533;
}

.bar-links .container {
  height: 100%;
}

.bar-links .links {
  display: table;
  width: 100%;
  height: 100%;
}

.bar-links .links a {
  position: relative;
  display: inline-block;
  height: 52px;
  padding: 0px 20px;
  margin-right: 20px;
  border: 2px solid #fff;
  color: #fff;
  font-family: 'SemiBold';
  font-size: 14px;
  line-height: 52px;
  text-transform: uppercase;
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.bar-links .links a:hover {
  color: #b15533;
  background-color: #fff;
}

.bar-links .form-group {
  float: initial;
  max-width: 360px;
  margin: 30px auto;
}

@media (max-width: 767px) {
  .bar-links {
    height: auto;
  }
  .bar-links .links {
    text-align: center;
  }
  .bar-links .links a {
    display: block;
    margin: 10px 0px;
    font-size: 12px;
  }
}

.pages {
  padding: 60px 10px;
}

.pages .container {
  position: relative;
}

.pages h3 {
  color: #414141;
  font-family: 'Bold';
  font-size: 22px;
  margin-bottom: 40px;
}

.pages h4 {
  color: #b15533;
  font-family: 'Bold';
  font-size: 20px;
  margin-bottom: 40px;
}

.pages p {
  font-size: 18px;
  margin-bottom: 40px;
  margin-top: -20px;
}

.pages p a {
  text-decoration: underline;
  color: #656565;
}

.pages p a:before {
  display: none;
}

.pages ul {
  counter-reset: numer;
  padding-left: 20px;
  margin-bottom: 40px;
}

.pages ul li {
  list-style-type: none;
  counter-increment: numer;
  border-top: none !important;
  border-bottom: none !important;
  font-size: 18px;
  margin: 20px 0px;
}

.pages ul li:before {
  position: absolute;
  content: counter(numer, disc);
  margin: 12px 10px 0px -20px;
  color: #b15533;
  font-size: 46px;
  line-height: 0;
}

.pages ul li a {
  text-decoration: underline;
  color: #656565;
}

.pages ul li a:before {
  display: none;
}

.pages .box {
  position: relative;
  min-height: 100px;
}

.pages .box div {
  width: 100%;
  margin-bottom: 40px;
}

.pages .box div img {
  display: table;
  width: 100%;
  max-width: 650px;
  margin: 0px auto 60px;
}

.pages .box div a {
  color: #656565;
  font-size: 18px;
}

.pages .box div a:before {
  content: '\e808';
  font-family: 'icons';
  speak: none;
  padding: 8px;
  margin: 0px 10px 0px 0px;
  border: 2px solid #b15533;
  border-radius: 100%;
  color: #b15533;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
}

.pages .box:first-child:after {
  content: '';
  position: absolute;
  right: -40px;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #e0dddc;
}

.pages .goup {
  position: absolute;
  right: 15px;
  bottom: 0;
  cursor: pointer;
}

.pages .goup icon {
  display: inline-block;
  width: 42px;
  height: 42px;
  border: 2px solid #b15533;
  border-radius: 100%;
  color: #b15533;
  font-size: 20px;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}

.pages .table {
  width: 100%;
  overflow: hidden;
}

.pages .table img {
  max-width: inherit !important;
}

.pages table {
  display: block;
  width: 100%;
  margin: 0px auto 60px;
  overflow-y: hidden;
}

.pages table tbody {
  display: table;
  width: 100%;
  max-width: 650px;
}

.pages table tr {
  height: 44px;
}

.pages table tr th, .pages table tr td {
  padding: 10px;
  border: 1px solid #656565;
  text-align: center;
}

.pages table tr th {
  background-color: #f5f5f5;
  color: #b15533;
  font-size: 16px;
  text-transform: uppercase;
}

.pages table tr td {
  background-color: #fff;
  color: #414141;
  font-size: 16px;
}

.pages table tr td span {
  display: block;
  text-align: left;
}

.pages table tr td img {
  display: table;
  margin: auto;
}

.btn-back {
  display: table;
  margin-bottom: 20px;
  color: #b15533;
  cursor: pointer;
}

.btn-back icon {
  display: inline-table;
  width: 24px;
  height: 24px;
  border: 1px solid #b15533;
  border-radius: 100%;
  font-size: 12px;
  text-align: center;
  transform: rotate(90deg);
}

.btn-back icon:before {
  display: block;
  margin-top: 6px;
}

.btn-back span {
  display: inline-table;
  margin: 0px 5px;
  vertical-align: middle;
  display: none;
}

@media (max-width: 1199px) {
  .pages .box:first-child:after {
    right: 0px;
  }
}

@media (max-width: 991px) {
  .pages .box:first-child:after {
    display: none;
  }
  .pages .goup {
    bottom: -40px;
  }
}

.boxes {
  width: 100%;
  height: auto;
  padding: 35px 0px;
  background-image: url("../../img/pixel-line.jpg");
  background-repeat: repeat-x;
}

.boxes h3 {
  padding: 0px 20px 20px;
  color: #b15533;
  font-family: 'Bold';
  font-size: 28px;
  text-transform: uppercase;
  line-height: 125%;
  text-align: center;
  display: none;
}

.boxes .box {
  padding-top: 15px;
  padding-bottom: 15px;
}

.boxes .box .imag {
  width: 100%;
  height: 220px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.boxes .box .info {
  position: relative;
  display: block;
  width: 100%;
  height: 220px;
  padding: 30px 20px 20px;
  background-color: #fff;
  text-align: center;
}

.boxes .box .info .icon {
  position: absolute;
  left: 0;
  right: 0;
  top: -40px;
  width: 80px;
  height: 80px;
  margin: auto;
  border-radius: 100%;
  background-color: #b15533;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-size: 80%;
  display: none;
}

.boxes .box .info .title {
  display: block;
  color: #414141;
  font-family: 'Bold';
  font-size: 19px;
  text-transform: uppercase;
  line-height: 125%;
}

.boxes .box .info .text {
  display: block;
  max-width: 400px;
  margin: 20px auto;
  color: #656565;
  font-size: 15px;
}

.boxes .box .info .link {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: auto;
  color: #b15533;
  font-family: 'Bold';
  font-size: 15px;
  text-transform: uppercase;
}

.boxes .box .info .link icon {
  display: inline-block;
  width: auto;
  max-width: 0px;
  vertical-align: bottom;
  overflow: hidden;
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.boxes .box .info:hover .link icon {
  max-width: 50px;
}

.home .boxes h3 {
  display: block;
}

.home .boxes .box .info {
  height: 240px;
  padding-top: 60px;
}

.home .boxes .box .info .icon {
  display: block;
}

@media (max-width: 1199px) {
  .boxes .box .imag {
    height: 200px;
  }
  .boxes .box .info {
    height: 260px;
  }
  .home .boxes .box .info {
    height: 300px;
  }
}

@media (max-width: 991px) {
  .boxes .box .imag {
    height: 160px;
  }
  .boxes .box .info {
    height: 280px;
  }
  .home .boxes .box .info {
    height: 340px;
  }
}

@media (max-width: 767px) {
  .boxes .box {
    margin: auto;
    max-width: 460px;
  }
  .boxes .box .info {
    height: auto;
    padding: 30px 20px 50px;
  }
  .home .boxes h3 {
    font-size: 20px;
  }
  .home .boxes .box .info {
    height: auto;
  }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// INFORMATION */
.products .scrollposition {
  position: absolute;
  margin-top: -164px;
}

.products .filter .area {
  width: 100%;
  padding: 30px 0px 10px;
  background-color: #b15533;
}

.products .filter .area .btn-filter {
  display: table;
  margin: auto;
  padding: 12px 0px;
}

.products .filter .area .btn-filter .toggle-btn {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  background-color: #e4e4e4;
  border-radius: 28px;
  border: 1px solid #fff;
  overflow: hidden;
  vertical-align: middle;
  box-shadow: inset -2px 2px 15px -5px #000, -5px 0 10px -5px #000;
  cursor: pointer;
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.products .filter .area .btn-filter .toggle-btn:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  background-color: #fff;
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.products .filter .area .btn-filter .toggle-btn.active {
  background-color: #894228;
  box-shadow: inset -2px 2px 15px -5px #000, 5px 0 10px -5px #000;
  cursor: pointer;
}

.products .filter .area .btn-filter .toggle-btn.active:before {
  left: 26px;
}

.products .filter .area .btn-filter .clean-btn {
  padding: 4px 0px;
  cursor: pointer;
}

.products .filter .area .btn-filter .clean-btn icon {
  display: inline-block;
  width: 19px;
  height: 21px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  vertical-align: middle;
  transform-origin: 50% 50%;
}

.products .filter .area .btn-filter .clean-btn icon:before {
  display: table-cell;
}

.products .filter .area .btn-filter .clean-btn:active icon {
  transform: rotate(-160deg);
  -moz-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  -webkit-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
}

.products .filter .area .btn-filter .clean-btn:not(:active) icon {
  transform: rotate(0deg);
  -moz-transition: 100ms ease-in-out;
  -o-transition: 100ms ease-in-out;
  -webkit-transition: 100ms ease-in-out;
  transition: 100ms ease-in-out;
}

.products .filter .area .btn-filter span {
  display: inline-block;
  padding: 0px 10px;
  color: #fff;
  font-size: 13px;
  font-family: 'Bold';
  text-transform: uppercase;
  vertical-align: middle;
}

.products .itens {
  position: relative;
  padding-bottom: 40px;
  min-height: 320px;
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.products .itens .loader {
  position: relative;
  left: 0;
  right: 0;
  top: 60px;
  margin: auto;
  z-index: 0;
}

.products .itens .noresults {
  position: relative;
  top: 60px;
  color: #b15533;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}

.products .itens .billhead {
  width: 100%;
  padding: 30px 0px;
  text-align: center;
}

.products .itens .billhead .btn-link, .products .itens .billhead .btn-file, .products .itens .billhead .btn-favorites {
  display: inline-block;
  margin: 0px 10px;
  cursor: pointer;
}

.products .itens .billhead .btn-link icon, .products .itens .billhead .btn-file icon, .products .itens .billhead .btn-favorites icon {
  display: inline-block;
  width: 42px;
  height: 42px;
  border: 2px solid #b15533;
  border-radius: 100%;
  color: #b15533;
  font-size: 20px;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.products .itens .billhead .btn-link span, .products .itens .billhead .btn-file span, .products .itens .billhead .btn-favorites span {
  display: inline-block;
  padding: 0px 10px;
  color: #656565;
  font-family: 'Bold';
  font-size: 12px;
  text-transform: uppercase;
  vertical-align: middle;
}

.products .itens .billhead .btn-link.active icon, .products .itens .billhead .btn-file.active icon, .products .itens .billhead .btn-favorites.active icon {
  background-color: #b15533;
  color: #fff;
}

.products .itens .box-item {
  position: relative;
  height: 420px;
  background-color: #fff;
  margin-bottom: 30px;
}

.products .itens .box-item icon {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #656565;
  font-size: 28px;
  z-index: 1;
  cursor: pointer;
}

.products .itens .box-item icon.fav_cheio {
  color: #b15533;
}

.products .itens .box-item .item {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 30px 10px 10px;
  cursor: pointer;
}

.products .itens .box-item .item .imag {
  width: 100%;
  height: 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-size: contain;
}

.products .itens .box-item .item .text {
  display: table;
  width: 100%;
  height: 25%;
  text-align: center;
}

.products .itens .box-item .item .text .marca {
  display: block;
  padding-bottom: 2px;
  color: #656565;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 125%;
}

.products .itens .box-item .item .text .nome {
  display: block;
  color: #b15533;
  font-family: 'Bold';
  font-size: 14px;
  text-transform: uppercase;
  line-height: 125%;
}

.products .itens .btn-more {
  display: table;
  position: relative;
  width: 180px;
  height: 46px;
  margin: 10px auto;
  background-color: #b15533;
  color: #fff;
  font-family: 'SemiBold';
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  line-height: 46px;
  cursor: pointer;
  z-index: 0;
}

.products .itens.v-enter, .products .itens.v-leave {
  opacity: 0;
}

.products .itens.v-enter-to {
  opacity: 1;
  -moz-transition: 250ms ease-in-out 250ms;
  -o-transition: 250ms ease-in-out 250ms;
  -webkit-transition: 250ms ease-in-out 250ms;
  transition: 250ms ease-in-out 250ms;
}

.products .itens.v-leave-to {
  opacity: 0;
  -moz-transition: all 250ms ease-in-out 250ms, opacity 250ms ease-in-out 0ms;
  -o-transition: all 250ms ease-in-out 250ms, opacity 250ms ease-in-out 0ms;
  -webkit-transition: all 250ms ease-in-out 250ms, opacity 250ms ease-in-out 0ms;
  transition: all 250ms ease-in-out 250ms, opacity 250ms ease-in-out 0ms;
}

.products .itens .col-item {
  -moz-transition: 800ms;
  -o-transition: 800ms;
  -webkit-transition: 800ms;
  transition: 800ms;
  overflow: hidden;
}

.products .itens .col-item.v-enter, .products .itens .col-item.v-leave {
  opacity: 0;
}

.products .itens .col-item.v-enter-to {
  opacity: 1;
  -moz-transition: 500ms ease-in-out 250ms;
  -o-transition: 500ms ease-in-out 250ms;
  -webkit-transition: 500ms ease-in-out 250ms;
  transition: 500ms ease-in-out 250ms;
}

.products .itens .col-item.v-leave-to {
  opacity: 0;
  -moz-transition: 0ms 0ms;
  -o-transition: 0ms 0ms;
  -webkit-transition: 0ms 0ms;
  transition: 0ms 0ms;
}

.products .detail {
  -moz-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.products .detail .communi {
  float: left;
  position: relative;
  width: 100%;
  padding: 60px 0px;
}

.products .detail .communi .btn-close {
  position: absolute;
  right: 10px;
  top: 30px;
  color: #b15533;
  font-size: 20px;
  cursor: pointer;
}

.products .detail .communi .item {
  position: relative;
  max-width: 420px;
  height: 420px;
  margin: 0px auto 20px;
  background-color: #fff;
  padding: 30px 10px 10px;
}

.products .detail .communi .item icon {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #656565;
  font-size: 28px;
  z-index: 1;
  cursor: pointer;
}

.products .detail .communi .item icon.fav_cheio {
  color: #b15533;
}

.products .detail .communi .item .imag {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-size: contain;
}

.products .detail .communi .links {
  text-align: center;
  margin-bottom: 40px;
}

.products .detail .communi .links .btn-info, .products .detail .communi .links .btn-file {
  display: block;
  margin: 10px 0px;
  cursor: pointer;
}

.products .detail .communi .links .btn-info icon, .products .detail .communi .links .btn-file icon {
  display: inline-block;
  width: 42px;
  height: 42px;
  border: 2px solid #b15533;
  border-radius: 100%;
  color: #b15533;
  font-size: 20px;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}

.products .detail .communi .links .btn-info span, .products .detail .communi .links .btn-file span {
  display: inline-block;
  padding: 0px 10px;
  color: #656565;
  font-family: 'Bold';
  font-size: 13px;
  text-transform: uppercase;
  vertical-align: middle;
}

.products .detail .communi .descri {
  padding: 0px 40px;
}

.products .detail .communi .descri h3 {
  display: block;
  color: #656565;
  font-size: 22px;
  text-transform: uppercase;
}

.products .detail .communi .descri h4 {
  display: block;
  margin-bottom: 40px;
  color: #b15533;
  font-family: 'Bold';
  font-size: 22px;
  text-transform: uppercase;
  width: 75%;
}

.products .detail .communi .descri h6 {
  display: block;
  color: #b15533;
  font-family: 'Bold';
  font-size: 16px;
}

.products .detail .communi .descri p {
  display: block;
  margin-bottom: 40px;
  font-size: 16px;
}

.products .detail .communi .descri h5 {
  display: block;
  margin-bottom: 20px;
  color: #b15533;
  font-family: 'Bold';
  font-size: 18px;
  text-transform: uppercase;
}

.products .detail .communi .descri h5 a {
  color: #b15533;
}

.products .detail .communi .descri .nutri li {
  float: left;
  width: 100%;
}

.products .detail .communi .descri .nutri li h6 {
  margin: 5px 0px -5px 5px;
}

.products .detail .communi .descri .nutri li .col {
  float: left;
  width: 50%;
  padding: 15px 0px;
}

.products .detail .communi .descri .nutri li icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: #b15533;
  color: #fff;
  font-size: 34px;
  text-align: center;
  line-height: 60px;
  vertical-align: middle;
}

.products .detail .communi .descri .nutri li .box {
  display: inline-block;
  padding: 0px 10px;
  vertical-align: middle;
}

.products .detail .communi .descri .nutri li .type {
  display: block;
  font-size: 16px;
  text-transform: uppercase;
}

.products .detail .communi .descri .nutri li .value {
  display: block;
  font-family: 'Bold';
  font-size: 18px;
}

.products .detail .communi .descri .nutri li.half {
  width: 50%;
}

.products .detail .communi .descri .nutri li.half .col {
  width: 100%;
}

.products .detail.v-enter-to {
  opacity: 1;
  -moz-transition: all 250ms ease-in-out 500ms;
  -o-transition: all 250ms ease-in-out 500ms;
  -webkit-transition: all 250ms ease-in-out 500ms;
  transition: all 250ms ease-in-out 500ms;
}

.products .detail.v-enter, .products .detail.v-leave {
  opacity: 0;
}

.information .boxes .btn-back {
  display: none;
}

@media (max-width: 1199px) {
  .products .itens .box-item {
    height: 380px;
  }
  .products .itens .box-item .item .imag {
    height: 75%;
  }
  .products .itens .box-item .item .text {
    height: 25%;
  }
}

@media (max-width: 991px) {
  .products .scrollposition {
    margin-top: -236px;
  }
  .products .filter .area .btn-filter {
    display: block;
  }
  .products .filter .area .afbt {
    text-align: center;
  }
  .products .filter .area .afbt .col-md-12 {
    display: inline-block;
  }
  .products .itens .billhead .btn-link, .products .itens .billhead .btn-file, .products .itens .billhead .btn-favorites {
    margin: 0px 5px;
  }
  .products .detail .communi .descri {
    padding: 0px 0px;
  }
  .products .detail .communi .descri h4 {
    width: 100%;
  }
  .products .detail .communi .descri .nutri li .col {
    width: 100%;
  }
  .products .detail .communi .descri .nutri li.half {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .products .scrollposition {
    margin-top: -60px;
  }
  .products .filter .area .btn-filter {
    padding: 12px 0px;
  }
  .products .filter .area .btn-filter .toggle-btn {
    width: 42px;
    height: 22px;
    border-radius: 22px;
  }
  .products .filter .area .btn-filter .toggle-btn:before {
    width: 20px;
    height: 20px;
  }
  .products .filter .area .btn-filter .toggle-btn.active:before {
    left: 20px;
  }
  .products .filter .area .btn-filter .clean-btn icon {
    font-size: 16px;
  }
  .products .filter .area .btn-filter span {
    padding: 0px 8px;
    font-size: 12px;
  }
  .products .itens .billhead .btn-link, .products .itens .billhead .btn-file, .products .itens .billhead .btn-favorites {
    margin: 4px;
  }
  .products .itens .billhead .btn-link icon, .products .itens .billhead .btn-file icon, .products .itens .billhead .btn-favorites icon {
    width: 28px;
    height: 28px;
    font-size: 14px;
    line-height: 24px;
  }
  .products .itens .billhead .btn-link span, .products .itens .billhead .btn-file span, .products .itens .billhead .btn-favorites span {
    padding: 0px 5px;
    font-size: 11px;
  }
  .products .detail .communi .links .btn-info icon, .products .detail .communi .links .btn-file icon {
    width: 28px;
    height: 28px;
    font-size: 14px;
    line-height: 24px;
  }
  .products .detail .communi .links .btn-info span, .products .detail .communi .links .btn-file span {
    padding: 0px 5px;
    font-size: 11px;
  }
}

@media (max-width: 479px) {
  .products .itens .col-item {
    padding-left: 5px;
    padding-right: 5px;
  }
  .products .itens .box-item {
    height: 240px;
    margin-bottom: 10px;
  }
  .products .itens .box-item .item .imag {
    height: 70%;
  }
  .products .itens .box-item .item .text {
    height: 30%;
  }
  .products .itens .box-item .item .text .marca {
    font-size: 10px;
  }
  .products .itens .box-item .item .text .nome {
    font-size: 10px;
  }
}
