/*/////////////////////////////////////////////////////////////////////////////////////////// MODERATION */
.bar-links{position:relative; width:100%; height:112px; background-color:$corDefault;
    .container{height:100%;}
    .links{display:table; width:100%; height:100%;
        a{position:relative; display:inline-block; height:52px; padding:0px 20px; margin-right:20px; border:2px solid #fff; color:#fff; font-family:'SemiBold'; font-size:14px; line-height:52px; text-transform:uppercase; @include transition($animatDefault);
            &:hover{color:$corDefault; background-color:#fff;}
        }
    }
    .form-group{float:initial; max-width:360px; margin:30px auto;}
}

@media (max-width:$screen-xs-max) {
    .bar-links{height:auto;
        .links{text-align:center;
            a{display:block; margin:10px 0px; font-size:12px;}
        }
    }
}

.pages{ padding:60px 10px;
    .container{position:relative;}
    h3{color:$corDarkG; font-family:'Bold'; font-size:22px; margin-bottom:40px;}
    h4{color:$corDefault; font-family:'Bold'; font-size:20px; margin-bottom:40px;}
    p{font-size:18px; margin-bottom:40px; margin-top:-20px;
        a{text-decoration:underline; color:$corLightG;
            &:before{display:none;}
        }
    }
    ul{counter-reset:numer; padding-left:20px; margin-bottom:40px;
        li{list-style-type:none; counter-increment:numer; border-top:none !important; border-bottom:none !important; font-size:18px; margin:20px 0px;
            &:before{position:absolute; content:counter(numer, disc); margin:12px 10px 0px -20px; color:$corDefault; font-size:46px; line-height:0;}
            a{text-decoration:underline; color:$corLightG;
                &:before{display:none;}
            }
        }
    }

    .box{position:relative; min-height:100px;
        div{width:100%; margin-bottom:40px;
            img{display:table; width:100%; max-width:650px; margin:0px auto 60px;}
            a{color:$corLightG; font-size:18px;
                &:before{content: '\e808'; font-family:'icons'; speak:none; padding:8px; margin:0px 10px 0px 0px; border:2px solid $corDefault; border-radius:100%; color:$corDefault; -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale; font-style:normal; font-weight:normal;}
            }
        }
        &:first-child{
            &:after{content:''; position:absolute; right:-40px; top:0; width:1px; height:100%; background-color:#e0dddc; }
        }
    }
    .goup{position:absolute; right:15px; bottom:0; cursor:pointer;
         icon{display:inline-block; width:42px; height:42px; border:2px solid $corDefault; border-radius:100%; color:$corDefault; font-size:20px; text-align:center; line-height:36px; vertical-align:middle;}
    }
    .table{width:100%; overflow:hidden;
        img{max-width:inherit !important;}
    }
    table{display:block; width:100%; margin:0px auto 60px; overflow-y:hidden;
        tbody{display:table; width:100%; max-width:650px;}
        tr{height:44px; 
            th, td{padding:10px; border:1px solid $corLightG; text-align:center;}
            th{background-color:#f5f5f5; color:$corDefault; font-size:16px; text-transform:uppercase;}
            td{background-color:#fff; color:$corDarkG; font-size:16px;
                span{display:block; text-align:left;}
                img{display:table; margin:auto;}
            }
        }
    }
}

.btn-back{display:table; margin-bottom:20px; color:$corDefault; cursor:pointer;
    icon{display:inline-table; width:24px; height:24px; border:1px solid $corDefault; border-radius:100%; font-size:12px; text-align:center; transform:rotate(90deg);
        &:before{display:block; margin-top:6px;}
    }
    span{display:inline-table; margin:0px 5px; vertical-align:middle; display:none;}
}

@media (max-width:$screen-md-max) {
    .pages{
        .box{
            &:first-child{
                &:after{right:0px;}
            }
        }
    }
}

@media (max-width:$screen-sm-max) {
    .pages{
        .box{
            &:first-child{
                &:after{display:none;}
            }
        }
        .goup{bottom:-40px;}
    }
}

.boxes{width:100%; height:auto; padding:35px 0px; background-image:url('../../img/pixel-line.jpg'); background-repeat:repeat-x;
    h3{padding:0px 20px 20px; color:$corDefault; font-family:'Bold'; font-size:28px; text-transform:uppercase; line-height:125%; text-align:center; display:none;}
    .box{padding-top:15px; padding-bottom:15px;
        .imag{width:100%; height:220px; @include backgrd(''); }
        .info{position:relative; display:block; width:100%; height:220px; padding:30px 20px 20px; background-color:#fff; text-align:center;
            .icon{position:absolute; left:0; right:0; top:-40px; width:80px; height:80px; margin:auto; border-radius:100%; background-color:$corDefault; @include backgrd(''); background-size:80%; display:none;}
            .title{display:block; color:$corDarkG; font-family:'Bold'; font-size:19px; text-transform:uppercase; line-height:125%;}
            .text{display:block; max-width:400px; margin:20px auto; color:$corLightG; font-size:15px;}
            .link{position:absolute; left:0; right:0; bottom:20px; margin:auto; color:$corDefault; font-family:'Bold'; font-size:15px; text-transform:uppercase;
                icon{display:inline-block; width:auto; max-width:0px; vertical-align:bottom; overflow:hidden; @include transition($animatDefault);}
            }
            &:hover{
                .link{
                    icon{max-width:50px;}
                }
            }
        }
    }
}

.home{
    .boxes{
        h3{display:block;}
        .box .info{ height:240px; padding-top:60px;
            .icon{display:block;}
        }
    }
}

@media (max-width:$screen-md-max) {
    .boxes{
        .box{
            .imag{height:200px;}
            .info{height:260px;}
        }
    }
    .home{
        .boxes{
            .box .info{height:300px;}
        }
    }
}

@media (max-width:$screen-sm-max) {
    .boxes{
        .box{
            .imag{height:160px;}
            .info{height:280px;}
        }
    }
    .home{
        .boxes{
            .box .info{height:340px;}
        }
    }
}

@media (max-width:$screen-xs-max) {
    .boxes{
        .box{margin:auto; max-width:460px;
            .info{height:auto; padding:30px 20px 50px;}
        }
    }
    .home{
        .boxes{
            h3{font-size:20px;}
            .box .info{height:auto;}
        }
    }
}

@media (max-width:$screen-xs-min - 1) {

}