/*/////////////////////////////////////////////////////////////////////////////////////////// FONTS */
/* font NovelSansPro */
@font-face {
    font-family: 'Regular';
    src:url('../../fonts/32D778_6_0.woff2') format('woff2'),
        url('../../fonts/32D778_6_0.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
 
 @font-face {
    font-family: 'Bold';
    src:url('../../fonts/32D778_A_0.woff2') format('woff2'),
        url('../../fonts/32D778_A_0.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SemiBold';
    src:url('../../fonts/32D778_5_0.woff2') format('woff2'),
        url('../../fonts/32D778_5_0.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/*@font-face {
    font-family: 'ExtraBold';
    src:url('../../fonts/32D778_7_0.woff2') format('woff2'),
        url('../../fonts/32D778_7_0.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/

@font-face {
    font-family: 'icons';
    src:url('../../fonts/icons.woff2') format('woff2'),
        url('../../fonts/icons.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

icon, .icon {
    &:before {
        font-family:'icons';
        speak: none;
        margin: 0px 5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-style: normal;
        font-weight: normal;
    }

    &.acucar:before { content: '\e800'; } /* '' */
    &.alcool:before { content: '\e801'; } /* '' */
    &.close:before { content: '\e802'; } /* '' */
    &.download:before { content: '\e803'; } /* '' */
    &.estrela:before { content: '\e804'; } /* '' */
    &.fav_cheio:before { content: '\e805'; } /* '' */
    &.fav_vazio:before { content: '\e806'; } /* '' */
    &.info:before { content: '\e807'; } /* '' */
    &.link:before { content: '\e808'; } /* '' */
    &.seta1_baixo:before { content: '\e809'; } /* '' */
    &.seta1_cima:before { content: '\e80a'; } /* '' */
    &.seta2_baixo:before { content: '\e80b'; } /* '' */
    &.seta2_cima:before { content: '\e80c'; } /* '' */
    &.seta2_dir:before { content: '\e80d'; } /* '' */
    &.seta2_esq:before { content: '\e80e'; } /* '' */
    &.valor_energetico:before { content: '\e80f'; } /* '' */
    &.vegan:before { content: '\e810'; } /* '' */
    &.alergenicos:before { content: '\e811'; } /* '' */
    &.gluten:before { content: '\e812'; } /* '' */
    &.vegetarian:before { content: '\e813'; } /* '' */
    &.clear:before { content: '\e814'; } /* '' */
    &.bola:before { content: '●'; }
}

.anim-spin {
  &:before {
    @include animation( spin 2s infinite linear );
    display: inline-block;
  }
}

@include keyframes(spin) {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}